<template>
<div>
    <v-expand-transition>
        <div v-show="candidateReady">
            <h1>Ihr Dashboard</h1>

            <h2 class="mt-4 col-heading">1. Persönliche Daten eintragen</h2>
          <v-card class="mb-4">
            <v-card-text>
              <div class="mb-4">Anrede <span class="text-primary">*</span></div>
              <div class="text--primary">
                <v-select
                    v-model="candidate.anrede"
                    :items="anredeOptions"
                    placeholder="Option auswählen"
                    @change="patch"
                ></v-select>
              </div>
            </v-card-text>
          </v-card>
            <v-card class="mb-4">
                <v-card-text>
                    <div class="mb-4">Name <span class="text-primary">*</span></div>
                    <v-container>
                        <v-row>
                            <v-col cols="6" class="pa-0 pr-2">
                                <v-text-field
                                    v-model="candidate.first_name"
                                    hint="Vorname"
                                    placeholder="Petra"
                                    persistent-hint
                                    hide-details="auto"
                                    @blur="patch"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="6" class="pa-0">
                                <v-text-field
                                    v-model="candidate.last_name"
                                    hint="Nachname"
                                    placeholder="Musterfrau"
                                    persistent-hint
                                    hide-details="auto"
                                    @blur="patch"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-card>

            <v-card class="mb-4">
                <v-card-text>
                    <div class="mb-4">E-Mail <span class="text-primary">*</span></div>
                    <div class="text--primary">
                        <em v-if="!candidate.email">
                            keine E-Mail
                        </em>
                        <em v-else>
                            {{ candidate.email }}
                        </em>
                    </div>
                </v-card-text>
            </v-card>

            <v-card class="mb-4">
                <v-card-text>
                    <div class="mb-4">Telefonnummer <span class="text-primary">*</span></div>
                    <v-container>
                        <v-row>
                            <v-col cols="2" class="pa-0 pr-1">
                                <v-text-field
                                    v-model="candidate.phone_country"
                                    hint="Land"
                                    placeholder="+49"
                                    persistent-hint
                                    hide-details="auto"
                                    @blur="patch"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="3" class="pa-0 pr-1">
                                <v-text-field
                                    v-model="candidate.phone_area"
                                    hint="Vorwahl"
                                    placeholder="177"
                                    persistent-hint
                                    hide-details="auto"
                                    @blur="patch"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="7" class="pa-0">
                                <v-text-field
                                    v-model="candidate.phone_number"
                                    hint="Nummer"
                                    placeholder="0123"
                                    persistent-hint
                                    hide-details="auto"
                                    @blur="patch"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-card>

            <v-card class="mb-4">
                <v-card-text>
                    <v-checkbox
                        v-model="candidate.internal"
                        label="Ich arbeite bereits bei ERGO"
                        color="primary"
                        :value="true"
                        class="mt-0"
                        @change="patch"
                        hide-details
                    ></v-checkbox>
                </v-card-text>
            </v-card>

            <v-card class="mb-4">
              <v-card-text>
                <div class="mb-4">Ihre Wunschressorts <span class="text-primary">*</span></div>
                <v-select
                  v-model="candidate.resortsSelfSelected"
                  :items="availableRessorts"
                  multiple
                  chips
                  item-text="name"
                  item-value="id"
                  placeholder="Nichts ausgewählt"
                  @change="patch"
                  :loading="!availableRessortsReady"
                  :disabled="!availableRessortsReady"
                  :item-disabled="item => [2, 4, 5, 7].includes(item.id)"
                >
                </v-select>
              </v-card-text>
            </v-card>

            <v-card class="mb-4">
                <v-card-text>
                    <div class="mb-4">CV <span class="text-primary">*</span></div>
                    <file-upload
                        type="cv"
                        emptyText="Kein CV hochgeladen"
                        :availableFiles="candidate.cv"
                        v-on:upload-start="patchLoading = true"
                        v-on:alert="emitPass"
                        v-on:upload-end="patchLoading = false, get(), showChangesSaved()"
                    ></file-upload>
                </v-card-text>
            </v-card>

          <v-card class="mb-4">
            <v-card-text>
              <v-checkbox
                  v-model="candidate.disability"
                  label="Schwerbehinderung/Gleichstellung (freiwillige Angabe)"
                  color="primary"
                  :value="true"
                  class="mt-0"
                  @change="patch"
                  hide-details
              ></v-checkbox>
              <small> Die Angabe einer bestehenden Schwerbehinderung, Gleichstellung mit einer schwerbehinderten Person oder einem laufenden Antragsverfahren ist freiwillig. Die Daten werden von uns verwendet, um die zuständigen Stellen rechtzeitig einbinden zu können. Damit stellen wir eine Gleichbehandlung aller Bewerber/Bewerberinnen sicher und schließen eine Benachteiligung aus erkrankungs- oder behinderungsbedingten Gründen aus. </small>
            </v-card-text>
          </v-card>

            <v-card class="mb-4">
                <v-card-text>
                    <div class="mb-4">Zeugnisse <span class="text-primary">*</span></div>
                    <file-upload
                        type="diplomas"
                        emptyText="Keine Zeugnisse hochgeladen"
                        :availableFiles="candidate.diplomas"
                        v-on:upload-start="patchLoading = true"
                        v-on:upload-end="patchLoading = false, get(), showChangesSaved()"
                        v-on:alert="emitPass"
                    ></file-upload>
                </v-card-text>
            </v-card>

            <h2 class="mb-4 col-heading">2. Online Test abschließen</h2>
            <v-btn
              class="primary zum-assessment mb-4 mt-2"
              :href="`${backendUrl}/aon/assessment`"
              target="_blank"
              elevation="8"
              block
              rounded
            >
              Zum Online Test
              <v-icon
                dark
                right
              >
                mdi-open-in-new
              </v-icon>
            </v-btn>

            <div class="px-4 mt-1 mb-4 text-caption">
                Sie werden auf eine externe Seite weitergeleitet.
            </div>

            <div class="safety-space"></div>

            <v-dialog
                v-model="withdrawDialog"
                width="500"
                >
                <template v-slot:activator="{ on, attrs }">
                    <div class="px-4 mt-2 text-caption">
                        Wenn Sie Ihre <a v-bind="attrs" v-on="on">Bewerbung zurückziehen</a>, werden alle Ihre Daten von unseren Servern
                        <strong>unwiderruflich</strong> gelöscht.
                    </div>
                </template>

                <v-expand-transition>
                    <v-alert
                        :type="withdrawMessageType"
                        v-show="!!withdrawMessage"
                        class="mt-4"
                        :prominent="withdrawMessageType === 'success'"
                    >
                        {{ withdrawMessage }}
                    </v-alert>
                </v-expand-transition>

                <v-card>
                    <v-card-title class="mb-4">
                        <h5>Bewerbung zurückziehen</h5>
                    </v-card-title>

                    <v-card-text>
                        Sind Sie sicher, dass Sie Ihre Bewerbung zurückziehen und
                        alle Daten löschen möchten? Dieser Schritt kann nicht
                        rückgängig gemacht werden.
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn
                            color="primary"
                            dark
                            class="my-4 px-4"
                            @click="withdrawApplication"
                            :loading="withdrawLoading"
                            elevation="8"
                            rounded
                        >
                            Bewerbung zurückziehen
                        </v-btn>

                        <v-btn
                            color="primary"
                            text
                            @click="withdrawDialog = false"
                            :disabled="withdrawLoading"
                            class="px-4"
                            rounded
                        >
                            Abbrechen
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>
    </v-expand-transition>

    <div class="text-center">
        <v-fade-transition>
            <v-progress-circular
                indeterminate
                class="px-4"
                color="primary"
                size="100"
                width="10"
                v-show="loading && !candidateReady"
            ></v-progress-circular>
        </v-fade-transition>
    </div>

    <div class="patch-in-progress pa-4">
        <v-fade-transition>
            <v-progress-circular
                indeterminate
                class="px-4"
                color="primary"
                size="35"
                width="5"
                v-show="patchLoading"
            ></v-progress-circular>
        </v-fade-transition>
    </div>

    <v-snackbar
      v-model="changesSaved"
      :timeout="3000"
      transition="scroll-y-reverse-transition"
    >
      <v-icon
        dark
        color="success"
        class="mr-4"
      >
        mdi-check-circle
      </v-icon>
      Änderungen gespeichert
    </v-snackbar>
</div>
</template>

<script>
import CandidateController from '@/controllers/candidate-controller'
import FileUpload from './FileUpload'
import {emitNetworkError} from './utils'

export default {
  name: 'Home',
  data(){
    return {
      loading: false,
      patchLoading: false,

      candidateReady: false,
      fileDownloadLock: false,

      withdrawDialog: false,
      withdrawMessage: '',
      withdrawMessageType: 'info',
      withdrawLoading: false,

      availableRessortsReady: false,
      availableRessorts: [],

      changesSaved: false,

      candidate: Object,
      anredeOptions: [ 'Divers', 'Herr', 'Frau', 'Unbestimmt' ],
    }
  },
  mounted(){
    this.get()
    this.getResorts()
  },
  computed: {
    backendUrl(){
      return process.env.VUE_APP_BACKEND_URL
    },
  },
  methods: {
    emitPass(type, message){
      this.$emit('alert', type, message)
    },
    showChangesSaved(){
      const doIt = () => {
        this.changesSaved = true
      }

      doIt.bind(this)

      setTimeout(doIt, 200)
    },
    async getResorts(){
      try {
        const response = await CandidateController.availableRessorts()
        this.availableRessorts = response.data.map(resort => {
          if ([2, 4, 5, 6, 7].includes(resort.id)) {
            return { ...resort, name: `${resort.name} (Vergeben)` }
          }
          return resort
        })
        this.availableRessortsReady = true
      } catch (e) {
        console.log(e)

        const status = e.response.status

        if (status === 401) return

        emitNetworkError(this)
      }
    },
    async get(){
      this.loading = true

      try {
        const status = await CandidateController.get()
        this.candidate = status.data

        this.candidateReady = true
      } catch (e) {
        console.log(e)

        const status = e.response.status

        if (status === 401){
          this.$router.push({name: 'requestLogin'})
          return
        }

        emitNetworkError(this)
      }

      this.loading = false
    },
    async logout(){
      this.loading = true
      try {
        await CandidateController.logout()
        this.candidateReady = false

        this.$emit('alert', 'info', 'Sie wurden erfolgreich ausgeloggt.')
        this.$router.push({name: 'requestLogin'})
      } catch (e) {
        console.log(e)
        emitNetworkError(this)
      }

      this.loading = false
    },
    async patch(){
      this.patchLoading = true

      try {
        await CandidateController.patch(this.candidate)
        this.showChangesSaved()
      } catch (e) {
        console.log(e)
        this.$emit(
          'alert',
          'error',
          'Ihre Änderungen konnten nicht gespeichert werden.'
        )
      }

      this.patchLoading = false
    },
    async withdrawApplication(){
      this.withdrawLoading = true

      try {
        await CandidateController.delete()
        this.$emit(
          'alert',
          'info',
          'Ihre Daten wurden zur Löschung vorgemerkt und Ihre Bewerbung zurückgezogen.' 
          + ' Sie erhalten in Kürze eine Bestätigung per E-Mail.'
        )
        this.$router.push({name: 'requestLogin'})
      } catch (e) {
        console.log(e)
        this.withdrawMessageType = 'error'
        this.withdrawMessage =
          'Ein Netzwerk-Fehler ist aufgetreten. Bitte probieren Sie es erneut.'
      }

      this.withdrawLoading = false
    }
  },
  watch: {
    withdrawDialog(){
      this.message = ''
    }
  },
  components: {
    FileUpload,
  }
}
</script>

<style>
.zum-assessment {
  max-width: 400px;
}

.safety-space {
    height: 100px;
}

.patch-in-progress {
  position: fixed;
  bottom: 0;
  right: 0;
}
</style>
