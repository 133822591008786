<template>
  <div
      style="background-color: #F4F4F4; min-height: 100vh; display: flex; flex-direction: column; justify-content: space-between">
    <!--<CookieBanner/>-->
    <v-container class="mt-0 pt-0" fluid style="max-width: 800px; position: relative;">
      <nav-bar>
        <router-link style="text-decoration: none" :to="'survey'" class="calltoaction-small">
          <div class="btn-calltoaction-small text-center d-flex align-center">
            <div style="width: 100%" class="my-auto mx-auto"><span style="font-size: 14px; text-decoration: none">Jetzt bewerben!</span>
            </div>
          </div>
        </router-link>
      </nav-bar>
      <v-main>
        <h2 class="mt-4">Information zur Verarbeitung Ihrer Bewerberdaten</h2>
        <p class="mt-2">
          Wir freuen uns sehr über Ihre Teilnahme am ERGO RISE-Bewerbungsverfahren, Ihr Interesse an unserem Unternehmen
          und auf Ihre Bewerbung. Sie stellen uns Ihr Profil für die ausgeschriebene Trainee-Stelle zur Verfügung. Ihre
          Angaben werden nur für diese Stelle geprüft.
        </p>
        <p>
          Die ERGO RISE-Landingpage verwendet nur technisch notwendige Cookies (sog. Session-Cookies), die Daten zur
          technischen Sitzungssteuerung im Speicher Ihres Browsers ablegen. Diese werden nicht zum Erheben von
          Nutzungsdaten verwendet und werden spätestens mit dem Schließen Ihres Browsers gelöscht.
        </p>
        <p>Bei jedem Zugriff werden die IP-Adresse, der Browsertyp und die abgerufene Seite anonymisiert
          gespeichert.</p>
        <p>
          Mit den folgenden Hinweisen informieren wir Sie über die Verarbeitung Ihrer personenbezogenen Daten durch die
          Unternehmen der ERGO Gruppe und die Ihnen nach dem Datenschutzrecht zustehenden Rechte im Falle einer
          Bewerbung im Rahmen des ERGO RISE-Bewerbungsverfahrens.
        </p>
        <div class="card mt-7" style="padding: 0">
          <v-expansion-panels accordion value="[0]">
            <v-expansion-panel>
              <v-expansion-panel-header>
                Wer ist für die Datenverarbeitung verantwortlich und wer ist Datenschutzbeauftragter?
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <p><b>Verantwortlicher für die Datenverarbeitung ist die ERGO Group AG, ERGO-Platz 1 in 40477
                  Düsseldorf.</b></p>
                <p>Den <b>Datenschutzbeauftragten</b> erreichen Sie per Post unter der o.g. Adresse mit dem Zusatz –
                  Datenschutzbeauftragter – oder per E-Mail unter <a href="mailto:datenschutz@ergo.de">datenschutz@ergo.de</a>.
                </p>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>
                Welche Datenkategorien nutzen wir und woher stammen diese?
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <p>Zu den verarbeiteten Kategorien personenbezogener Daten gehören insbesondere Ihre Stammdaten (wie
                  Vorname, Nachname, Namenszusätze, Staatsangehörigkeit und Personalnummer), Kontaktdaten (etwa private
                  Anschrift, (Mobil-)Telefonnummer, E-Mail-Adresse) sowie die Daten des gesamten Bewerbungsverfahrens
                  (Anschreiben, Zeugnisse, Fragebögen, Interviews, Qualifikationen und bisherige Tätigkeiten). Ihre
                  Bankdaten erheben und verarbeiten wir dann, wenn Sie uns um Erstattung Ihrer Fahrtkosten zu
                  Bewerbergesprächen und weiterer Kosten oder Auslagen bitten. Sofern Sie im Bewerbungsschreiben oder im
                  Laufe des Bewerbungsverfahrens auch besondere Kategorien personenbezogener Daten (wie
                  Gesundheitsdaten, Religionszugehörigkeit, Grad der Behinderung) freiwillig mitgeteilt haben, findet
                  eine Verarbeitung nur statt, wenn Sie hierin eingewilligt haben.</p>
                <p>Ihre personenbezogenen Daten werden in aller Regel direkt bei Ihnen im Rahmen des
                  Einstellungsprozesses erhoben. Daneben können wir Daten von Dritten (z.B. Stellenvermittlung) erhalten
                  haben.</p>
                <p>Zudem verarbeiten wir personenbezogene Daten, die wir aus öffentlich zugänglichen Quellen (z.B.
                  beruflichen sozialen Netzwerken) zulässigerweise gewonnen haben.</p>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>
                Für welche Zwecke und auf welcher Rechtsgrundlage werden Daten verarbeitet?
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <p>Wir verarbeiten Ihre personenbezogenen Daten unter Beachtung der Bestimmungen der
                  EU-Datenschutz-Grundverordnung (DS-GVO), des Bundesdatenschutzgesetzes (BDSG) sowie aller weiteren
                  maßgeblichen Gesetze (z.B. BetrVG, AGG, etc.).</p>
                <p>In erster Linie dient die Datenverarbeitung der Durchführung sowie Abwicklung des
                  Bewerbungsverfahrens und der Beurteilung, inwieweit eine Eignung für die betreffende Stelle vorliegt.
                  Im Ergebnis ist die Verarbeitung Ihrer Bewerberdaten erforderlich, um über die Begründung eines
                  Beschäftigungsverhältnisses entscheiden zu können. Die vorrangige Rechtsgrundlage hierfür ist Art. 6
                  Abs. 1 b) DS-GVO i.V.m. § 26 Abs. 1 BDSG. Zusätzlich holen wir Ihre Einwilligung ein, Art. 6 Abs. 1 a)
                  DS-GVO.</p>
                <p>Darüber hinaus sind wir aufgrund der europäischen Antiterrorverordnungen 2580/2001 und 881/2002
                  verpflichtet, Ihre Daten gegen die sog. „EU-Terrorlisten“ und „US-Sanktionslisten“ abzugleichen, um
                  sicherzustellen, dass zukünftig keine Gelder oder sonstigen wirtschaftlichen Ressourcen für
                  terroristische Zwecke bereitgestellt werden.</p>
                <h5> Datenverarbeitung zu statistischen Zwecken (z.B. Untersuchungen zum Bewerberverhalten) </h5>
                <p>Die Erstellung der Statistiken erfolgt ausschließlich zu eigenen Zwecken und erfolgt in keinem Fall
                  personalisiert, sondern anonymisiert.</p>
                <p>Wir werden Sie im Rahmen des ERGO RISE-Bewerbungsverfahrens nicht auffordern, uns sog. besondere
                  Kategorien personenbezogener Daten, wie zum Beispiel Angaben zu Ihrer Gesundheit, zu etwaiger
                  Gewerkschaftszughörigkeit oder Ihrer Religion mitzuteilen. Sollten Sie uns jedoch derartige
                  Informationen freiwillig zur Verfügung stellen, so erlauben Sie uns, diese Informationen im Rahmen der
                  Registrierung zu speichern und für die Zwecke dieses Bewerbungsverfahrens zu verwenden. Die
                  Verarbeitung besonderer Kategorien personenbezogener Daten beruht dann auf Ihrer Einwilligung nach
                  Art. 9 Abs. 2 a) DS-GVO i.V.m. § 26 Abs. 2 BDSG.</p>
                <p>Ihre Bewerberdaten werden jederzeit vertraulich behandelt. Sollten wir Ihre personenbezogenen Daten
                  für einen oben nicht genannten Zweck verarbeiten wollen, werden wir Sie zuvor darüber informieren.</p>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>
                Wer bekommt Ihre Daten?
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <p>Innerhalb unseres Unternehmens erhalten nur die Personen und Stellen (z.B. Fachbereich, Betriebsrat,
                  Schwerbehindertenvertretung) Ihre personenbezogenen Daten, die diese für die Einstellungsentscheidung
                  und zur Erfüllung unserer vertraglichen und gesetzlichen Pflichten benötigen.</p>
                <p>Innerhalb unserer Unternehmensgruppe werden Ihre Daten an bestimmte Unternehmen übermittelt, wenn
                  diese Datenverarbeitungsaufgaben für die in der Gruppe verbundenen Unternehmen zentral wahrnehmen
                  (z.B. konzernweites Bewerbermanagement).</p>
                <p>Daneben bedienen wir uns im Rahmen des Bewerbungsprozesses ggf. ergänzend von uns eingesetzter
                  Auftragnehmer oder Dienstleister.</p>
                <p>Darüber hinaus können wir Ihre personenbezogenen Daten an weitere Empfänger außerhalb des
                  Unternehmens übermitteln, soweit dies zur Begründung des Arbeitsverhältnisses notwendig ist (z.B.
                  prüfen wir im Falle einer Einstellung als Mitarbeiter der am Versicherungsvertrieb unmittelbar oder
                  maßgeblich beteiligt ist, ob ein Eintrag im Schuldnerverzeichnis und/oder ein Eintrag bei der
                  Auskunftsstelle über Versicherungs-/Bausparkassenaußendienst und Versicherungsmakler in Deutschland
                  e.V. (AVAD) vorliegt).</p>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>
                Welche Datenschutzrechte können Sie als Betroffener geltend machen?
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <p>Sie können unter der o.g. Adresse <b>Auskunft</b> über die zu Ihrer Person gespeicherten Daten
                  verlangen.
                  Darüber hinaus können Sie unter bestimmten Voraussetzungen die <b>Berichtigung</b> oder die <b>Löschung</b>
                  Ihrer
                  Daten verlangen. Ihnen kann weiterhin <b>ein Recht auf Einschränkung der Verarbeitung</b> Ihrer Daten
                  sowie
                  ein <b>Recht auf Herausgabe der von Ihnen bereitgestellten Daten</b> in einem strukturierten, gängigen
                  und
                  maschinenlesbaren Format zustehen.</p>
                <div style="border: 1px solid black; padding: 1em; margin: 1em;">
                  <h5>Widerspruchsrecht</h5>
                  <p><b>Sie haben das Recht, einer Verarbeitung Ihrer personenbezogenen Daten zu Zwecken der
                    Direktwerbung
                    ohne Angabe von Gründen zu widersprechen. Verarbeiten wir Ihre Daten zur Wahrung berechtigter
                    Interessen, können Sie dieser Verarbeitung aus Gründen, die sich aus Ihrer besonderen Situation
                    ergeben, widersprechen. Wir verarbeiten Ihre personenbezogenen Daten dann nicht mehr, es sei denn,
                    wir
                    können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte
                    und
                    Freiheiten überwiegen oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von
                    Rechtsansprüchen.</b></p>
                  <h5>Widerrufsrecht</h5>
                  <p class="mb-0"><b>Verarbeiten wir Ihre Daten aufgrund Ihrer Einwilligung, so haben Sie das Recht Ihre
                    Einwilligung mit Wirkung für die Zukunft zu widerrufen.</b></p>
                </div>
                <p>Sollten wir Ihre personenbezogenen Daten für einen oben nicht genannten Zweck verarbeiten wollen,
                  werden wir Sie im Rahmen der gesetzlichen Bestimmungen darüber zuvor informieren.</p>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>
                Wie lange werden Ihre Daten gespeichert?
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <p>Wir anonymisieren Ihre personenbezogenen Daten spätestens zwölf Monate nach Abschluss des
                  Bewerbungsverfahrens. Dies gilt nicht, sofern gesetzliche Bestimmungen der Anonymisierung
                  entgegenstehen oder die weitere Speicherung zu Zwecken der Beweisführung erforderlich ist oder Sie in
                  eine längere Speicherung eingewilligt haben.</p>
                <p>Sie können jederzeit Änderungen Ihrer Kontaktdaten über den jeweiligen Ansprechpartner aus dem
                  Recruiter-Team veranlassen. Bitte beachten Sie, dass sich Änderungen Ihres Profils auf die gesamte
                  Bewerbung auswirken können.</p>
                <p>Nach Abschluss des jeweiligen Verfahrens werden Ihre Daten anonymisiert und nur noch für statistische
                  Zwecke genutzt, so dass keinerlei Rückschlüsse mehr auf Sie gezogen werden können.</p>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>
                Wie übermitteln wir Daten ins außereuropäische Ausland?
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <p>Sollten wir personenbezogene Daten an Dienstleister außerhalb des Europäischen Wirtschaftsraums (EWR)
                  weitergeben, erfolgt die Weitergabe nur, soweit dem Drittland durch die EU-Kommission ein angemessenes
                  Datenschutzniveau bestätigt wurde oder andere angemessene Datenschutzgarantien (z. B. verbindliche
                  unternehmensinterne Datenschutzvorschriften oder Vereinbarung der Standardvertragsklauseln der
                  EU-Kommission) vorhanden sind. Detaillierte Information dazu sowie über das Datenschutzniveau bei
                  unseren Dienstleistern in Drittländern können Sie unter den oben genannten Kontaktinformationen
                  anfordern.</p>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>
                Sind Sie verpflichtet, Ihre Daten bereitzustellen?
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <p>Im Rahmen Ihrer Bewerbung müssen Sie diejenigen personenbezogenen Daten bereitstellen, die für die
                  Durchführung des Bewerbungsverfahrens und die Eignungsbeurteilung erforderlich sind. Ohne diese Daten
                  werden wir nicht in der Lage sein, das Bewerbungsverfahren durchführen und eine Entscheidung über die
                  Begründung eines Beschäftigungsverhältnisses treffen zu können.</p>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>
                Möchten Sie sich über den Umgang mit Ihren Daten beschweren?
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <p>Sie haben die Möglichkeit, sich an den oben genannten Datenschutzbeauftragten oder an eine
                  Datenschutzaufsichtsbehörde zu wenden. Die für uns zuständige Datenschutzaufsichtsbehörde ist:</p>
                <p>Landesbeauftragte für Datenschutz und Informationsfreiheit Nordrhein-Westfalen<br>
                  Kavalleriestraße 2-4, 40213 Düsseldorf</p>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
        <div class="d-flex justify-end mt-4">
          <span :style="{color: colors.ergowarmgreydarken3}">Stand: 30.11.2021</span>
        </div>
      </v-main>
    </v-container>
    <v-footer class="footer">
      <a style="text-decoration: none" class="mx-2" target="_blank" rel="noopener noreferrer"
         href="https://www.ergo.com/de/Impressum-Anbieterkennzeichnung">Impressum</a>
      <router-link style="text-decoration: none" target="_blank" rel="noopener noreferrer" class="mx-2" :to="'contact'">
        Kontakt
      </router-link>
    </v-footer>
  </div>
</template>

<script>
import NavBar from '@/components/shared/NavBar'
import colors from '@/utils/colors'

export default {
  name: 'PrivacyPage',
  data: function () {
    return {
      colors: colors,
    }
  },
  components: {
    NavBar
  }
}
</script>

<style scoped lang="scss">
@import "~@/components/shared/shared.scss";

.v-application--is-ltr .v-expansion-panel-header {
  font-size: larger
}

p:first-child {
  margin-top: 1em;
}

p:last-child {
  margin-bottom: 0;
}

.card-header {
  display: block;
  font-size: large;
  font-weight: bold;
}

.card-content {
  display: block;
  margin-top: 16px;
  font-size: small;
}

.card {
  display: block;
  width: 100%;
  padding: 28px;
  overflow: hidden;
  background-color: white;
  border-radius: 5px;
  box-shadow: 3px 3px 6px 0 #ccc, 8px 2px 12px -4px #ccc, 0 0 4px 0 #ccc;
  will-change: transform;
}
</style>