<template>
  <v-container>
    <v-container v-for="( question, index ) of questions" :key="index">
      <h4>
        {{ question.text }}
      </h4>
      <div v-for="(answer, index) of question.answers" :key="index">
        {{ filterAnswers(answer) }}
      </div>
    </v-container>
  </v-container>
</template>

<script>

import QuestionController from '@/controllers/question-controller'

export default {
  async beforeMount() {
    console.log('⚪️ Survey loaded')
    await this.loadQuestions()
  },
  data() {
    return {
      questions: []
    }
  },
  props: {
    candidate: Object
  },
  computed: {

  },
  methods: {
    filterAnswers(answer) {
      if (this.candidate.responses.some(response => response.answer_id === answer.id)) {
        return answer.text
      }
    },
    async loadQuestions () {
      try {
        const response = await QuestionController.getQuestions()
        this.questions = response.data
        console.log(response)
      } catch (error) {
        console.log( error )
      }
    },
  },
  components: {

  }
}
</script>

<style>

</style>
