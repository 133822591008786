<template>
  <v-container class="d-flex fill-height justify-center align-center">
    <v-card min-width="20em" class="rounded-lg pa-4">
      <v-card-title class="justify-space-around">
        <v-icon x-large color="primary" class="pb-2">mdi-account-question</v-icon>
      </v-card-title>
      <v-card-text class="text-center">
        <v-form v-model="validForm">
          <v-text-field
            v-model="email"
            label="E-Mail"
            type="text"
            filled
            rounded
            :rules="[rules.required, rules.email]"
          ></v-text-field>
          <v-text-field
            v-model="password"
            label="Passwort"
            type="password"
            filled
            rounded
            :rules="[rules.required]"
            @keyup.enter="login"
          ></v-text-field>
        </v-form>
        <v-alert
          v-model="error"
          type="error"
          dense
          dismissible
          transition="scale-transition"
        >{{ errorMessage }}</v-alert>
        <v-btn
          color="primary"
          rounded
          :loading="loading"
          :disabled="!validForm"
          @click.prevent="login"
        >Einloggen</v-btn>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>

import AuthController from '@/controllers/auth-controller'
import Store from '@/plugins/store'

export default {
  data() {
    return {
      email: '',
      password: '',
      loading: false,
      validForm: false,
      rules: {
        required: value => !!value || 'Dieses Feld wird benötigt',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Die E-Mail ist leider nicht gültig'
        }
      },
      error: false,
      errorMessage: '',
    }
  },
  methods: {
    async login() {

      this.loading = true
      this.error = false

      try {
        await AuthController.login({
          email: this.email,
          password: this.password
        })
        Store.auth.recruiter = true
        console.log( Store )
        this.$router.push({ name: 'recruiterDashboard' })
      } catch (error) {
        this.errorMessage = error.response.data.message
        this.error = true
        console.error(error)
      }

      this.loading = false
    },
  },
}
</script>

<style>

</style>
