import Axios from '@/plugins/axios'

const BASE = process.env.VUE_APP_BACKEND_URL || 'http://localhost:8000'

export default {
  // async getComment() {
  //   return Axios.get(`${BASE}/api/comment`)
  // },
  // async putComment(id, comment) {
  //   return Axios.put(`${BASE}/api/comment`, JSON.stringify({
  //     userID: id,
  //     text: comment
  //   }))
  // },
  // async deleteComment(id) {
  //   return Axios.delete('http://localhost:8000/api/comment/' + id)
  // }

  async getResorts() {
    return Axios.get(`${BASE}/api/resort`)
  }
}