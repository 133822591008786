<template>
  <v-app>
    <v-app-bar
        app
        flat
        clipped-left
        height="80"
        class="primary-gradient"
    >
      <v-btn
          elevation="0"
          rounded
          text
          dark
          class="py-2"
          height="64"
          href="/recruiter"
          :ripple="{ class: 'white--text' }"
      >
        <f-logo></f-logo>
      </v-btn>

      <span class="white--text text-h4">·</span>

      <img
          src="@/assets/images/ergo_logo.svg"
          alt="ergo logo"
          style="max-height: 50%;filter: brightness(100);padding-left: 20px;"
      >

      <v-spacer></v-spacer>

      <account-menu v-if="$route"></account-menu>
    </v-app-bar>

    <v-main class="grey lighten-3">
      <div class="pa-4">
        <router-view></router-view>
      </div>
    </v-main>
  </v-app>
</template>

<script>

import Logo from '@/components/recruiter-dashboard/Logo'
import AccountMenu from '@/components/recruiter-dashboard/AccountMenu'

export default {
  mounted() {
  },
  data() {
    return {

    }
  },

  components: {
    'f-logo': Logo,
    AccountMenu,
  },
}
</script>
<style>

.primary-gradient.v-app-bar {
  background: linear-gradient(
      90deg,
      var(--v-primary-base),
      var(--v-accent-base)
  );
}

@media print {
  .on-printer {
    position: absolute !important;
    width: 100vw;
    top: 0;
    left: 0;
    z-index: 10000000;
    background: white;
    height: auto !important;
    box-shadow: none !important;
  }

  .on-printer *[id="tab-details"] .col-4,
  .on-printer *[id="tab-details"] .col-2 {
    max-width: 50% !important;
    flex: 0 0 50% !important;
  }

  .on-printer-wrap {
    max-height: none !important;
  }

  .on-printer-wrap .v-tabs,
  .on-printer-wrap .candidate-actions,
  .on-printer-wrap .candidate-statuses,
  .candidate-list,
  nav {
    display: none !important;
  }

  main {
    padding: 0 !important;
  }
}
</style>
