// if undefined, return alt, else e
export function unf (e, alt) {
  return e !== undefined ? e : alt
}

// iff and unf chained
export function unfiff (e, alt, ...props) {
  return unf(iff(e, ...props), alt)
}

// tries to access prop after prop and if one chain is unavailable returns undefined
export function iff (obj, ...props) {
  let last = obj
  for (let i = 0; i < props.length; ++i) {
    const prop = props[i] // may be index as well
    if (
      !(typeof last === 'object') ||
      !(prop in last) ||
      typeof last[prop] === 'undefined'
    ) return undefined

    last = last[prop]
  }

  return last
}
