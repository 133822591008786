<template>
  <div>
    <a ref="downloadStorage" style="display: none"></a>
    <div class="text--primary">
      <em v-if="!availableFiles || availableFiles.length == 0">
        {{ emptyText }}
      </em>

      <v-chip
          v-else
          v-for="(elem, index) of availableFiles"
          v-bind:key="index"
          class="ma-2"
          color="primary"
          :disabled="loading"
          @click="download(elem)"
      >
        {{ elem }}
      </v-chip>
    </div>

    <v-form
        ref="form"
        v-on:submit.prevent="uploadSingleOrMultipleFiles"
        class="mt-4"
    >
      <v-file-input
          v-if="uploadAllowed"
          truncate-length="15"
          small-chips
          class="mb-5"
          :label="multiple ? 'Dateien hochladen' : 'Datei hochladen'"
          :name="`${ multiple ? 'files[]' : 'file' }`"
          v-model="singleOrMultipleFiles"
          :rules="rules"
          :multiple="multiple"
      ></v-file-input>

      <v-expand-transition>
        <v-btn
            v-show="showUploadButton"
            large
            color="primary"
            type="submit"
            block
            :outlined="loading"
            :disabled="loading"
            :loading="loading"
        >Hochladen
        </v-btn>
      </v-expand-transition>
    </v-form>
  </div>
</template>

<script>
import RecruiterController from '@/controllers/recruiter-controller'
import {emitNetworkError} from '@/components/candidate-dashboard/utils'

export default {
  data() {
    return {
      loading: false,
      isValid: false,

      singleOrMultipleFiles: this.multiple ? [] : null,

      allowedTypes: [
        'image/jpeg',
        'application/pdf',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.oasis.opendocument.text',
        'image/png',
      ],
    }
  },
  computed: {
    rulesMultiple() {
      return [
        vs => {
          if (!vs) return false

          for (const v of vs) {
            if (!this.allowedTypes.includes(v.type))
              return 'Alle Dateien müssen vom Typ PDF, DOC, DOCX, ODT, PNG oder JPG sein.'
          }

          return true
        },
      ]
    },
    rulesSingle() {
      return [
        v => {
          if (!v) return true

          return !!v && this.allowedTypes.includes(v.type)
              || 'Die Datei muss vom Typ PDF, DOC, DOCX, ODT, PNG oder JPG sein.'
        }
      ]
    },
    rules() {
      return this.multiple ? this.rulesMultiple : this.rulesSingle
    },
    showUploadButton() {
      return (
        this.multiple
          ? !!this.singleOrMultipleFiles && this.singleOrMultipleFiles.length > 0
          : !!this.singleOrMultipleFiles
      ) && this.isValid
    },
    multiple() {
      return this.type === 'diplomas' || this.type === 'recruiterFiles'
    },
    userid() {
      return this.user.id
    }
  },
  methods: {
    resetFiles() {
      this.singleOrMultipleFiles = this.multiple ? [] : null
    },
    async download(filename) {
      this.$emit('download-start')
      this.loading = true

      // @todo workaround remove later
      console.log(filename)
      window.open(
        RecruiterController.getFileAsRecruiterLink(
          {id: this.userid}, 
          this.type, 
          filename
        )
      )

      // try {
      //   const download = 
      //     await RecruiterController.getFileAsRecruiter({id: this.userid}, this.type, filename)

      //   const url = window.URL.createObjectURL(new Blob([download.data]))
      //   const link = this.$refs.downloadStorage
      //   link.href = url
      //   link.setAttribute('download', filename)
      //   link.click()
      // } catch (error) {
      //   console.log(error)
      //   emitNetworkError(this)
      // }

      this.loading = false
      this.$emit('download-end')
    },
    async uploadSingleOrMultipleFiles() {
      this.$emit('upload-start')
      this.loading = true

      try {
        const form = new FormData(this.$refs.form.$el)
        let response = 
          await RecruiterController.postFilesAsRecruiter(form, this.user)

        if (response.data.ok) {
          this.resetFiles()
        } else {
          this.$emit('alert', 'warning', response.data.message)
        }
      } catch (error) {
        console.log(error)
        emitNetworkError(this)
      }

      this.loading = false
      this.$emit('upload-end')
    },
  },
  watch: {
    singleOrMultipleFiles() {
      this.isValid = this.$refs.form.validate()
    },
  },
  props: {
    user: {
      required: true,
      type: Object
    },
    uploadAllowed: {
      default: true,
      required: false,
      type: Boolean
    },
    emptyText: {
      default: 'Nicht vorhanden',
      type: String,
    },
    type: {
      required: true,
      type: String,
      validator: function (value) {
        return ['cv', 'diplomas', 'recruiterFiles'].indexOf(value) !== -1
      },
    },
    availableFiles: {
      default() {
        return []
      },
      type: Array,
    },
  },
}
</script>