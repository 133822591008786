<template>
  <div v-if="candidate" :key="candidate">
    <div class="text-center" style="position:relative">
      <div class="pb-4">
        <h4 class="text-left mb-3 mt-4">Persönliche Daten</h4>
        <v-row style="font-size:smaller; text-align: start" class="justify-center">
          <v-col cols="3">
            EMAIL
          </v-col>
          <v-col style="font-size: small">
            <strong class="primary--text" style="overflow: visible">
              {{ candidate.email }}
            </strong>
          </v-col>
        </v-row>
        <v-row style="font-size:smaller; text-align: start" class="justify-center">
          <v-col cols="3">
            <span>ANREDE</span>
          </v-col>
          <v-col style="font-size: small">
              {{ candidate.anrede }}
          </v-col>
        </v-row>
        <v-row style="font-size:smaller; text-align: start" class="justify-center">
          <v-col cols="3">
            <span>NAME</span>
          </v-col>
          <v-col style="font-size: small">
            <strong>
              {{ candidate.first_name }} {{ candidate.last_name }}
            </strong>
          </v-col>
        </v-row>
        <v-row style="font-size:smaller; text-align: start" class="justify-center">
          <v-col cols="3">
            <span>TEL</span>
          </v-col>
          <v-col style="font-size: small">
            <strong>
              {{ candidate.phone_country }} {{ candidate.phone_area }} {{ candidate.phone_number }}
            </strong>
          </v-col>

        </v-row>
      </div>
     <div class="py-4">
        <v-row class="my-5 justify-center">
          <div class="candidate-statuses">
            <v-icon :color="getStatusColor( 'contacted' )"> {{ 'mdi-email-outline' }}</v-icon>
            <v-icon :color="getStatusColor('sheet')" class="flip-icon">mdi-file-outline</v-icon>
            <v-icon :color="getStatusColor('hidden')">mdi-eye-outline</v-icon>
          </div>
        </v-row>
      </div>

      <div class="pt-4">
        <h4 class="text-left mb-3 mt-4">Schwerbehinderung</h4>
        <p class="text-left">
          <em>{{ candidate.disability ? 'Ja' : 'Nein' }}</em>
        </p>
      </div>

      <div class="py-4">
        <h4 class="text-left mb-3 mt-4">Bewertung</h4>
        <v-row class="justify-center">
          <v-btn-toggle
              v-model="candidate.rating"
              mandatory
              @change="changeUserDetails()"
          >
            <v-btn>A</v-btn>
            <v-btn>B</v-btn>
            <v-btn>C</v-btn>
          </v-btn-toggle>
        </v-row>
      </div>

      <div class="py-4">
        <h4 class="text-left mb-3 mt-4">Aktuelle Phase</h4>
        <v-row class="justify-center px-6 pt-4">
          <v-select
              v-model="candidate.process_status"
              :items="processStatus"
              dense
              filled
              rounded
              @change="changeUserDetails()"
              class="py-1"
          >
            <template v-slot:selection="">
              {{ processStatusToStr(candidate.process_status) }}
            </template>
            <template v-slot:item="data">
              {{ processStatusToStr(data.item) }}
            </template>
          </v-select>
        </v-row>
      </div>

      <div class="py-4">
        <v-row class="justify-center mt-0">
          <v-checkbox @change="changeUserDetails()" v-model="candidate.docs_uploaded"
                      label="Dokumente hochgeladen"></v-checkbox>
        </v-row>
      </div>

      <div class="py-4">
        <h4 class="text-left mb-3 mt-4">Passende Ressorts</h4>
        <v-row>
          <v-select
              v-model="candidate.resorts"
              :items="modifiedResorts"
              multiple
              chips
              hint="Abteilung auswählen"
              persistent-hint
              class="px-4 mb-7 mt-0 pt-0"
              @change="changeUserDetails()"
              item-text="name"
              item-value="id"
              :item-disabled="item => [2, 4, 5, 7].includes(item.id)"
          >
          </v-select>
        </v-row>
      </div>

      <div class="py-4">
        <h4 class="text-left mb-3 mt-4">Wunschressorts</h4>
        <div class="px-4 text-left">
          <template v-if="candidate.resorts_self_selected && candidate.resorts_self_selected.length > 0">
            <v-chip 
              v-for="res in candidate.resorts_self_selected" 
              v-bind:key="res.id" 
              class="mr-2 mb-2"
            >
              {{ res.name }}
            </v-chip>
          </template>
          <template v-else>
            <em>
              bisher nicht ausgewählt
            </em>
          </template>
            
          <!-- <v-select
              v-model="candidate.resorts_self_selected"
              :items="resorts"
              multiple
              disabled
              hint="Ressorts, die den/die Kandidatin"
              persistent-hint
              class="px-4 mb-7 mt-0 pt-0"
              item-text="name"
              item-value="id"
          >
          </v-select> -->
        </div>
      </div>

      <div class="py-4">
        <h4 class="text-left mb-3 mt-4">Termin Offline Assessment</h4>
        <v-row class="justify-center ">
          <v-btn-toggle v-model="candidate.appointment" @change="changeUserDetails()" class="flex-wrap">
            <v-btn>10.04</v-btn>
            <v-btn>30.04</v-btn>
            <v-btn>16.05</v-btn>
            <v-btn>28.05</v-btn>
            <v-btn>05.06</v-btn>
            <v-btn>26.06</v-btn>
            <v-btn>10.07</v-btn>
            <v-btn>25.07</v-btn>
            <v-btn>14.08</v-btn>
          </v-btn-toggle>
        </v-row>
      </div>

      <div class="pt-4">
        <h4 class="text-left  mt-4">AON Scores</h4>
        <p class="text-left mb-3 text-xs">ID: {{ candidate.aon_id}}</p>
        <v-row>
          <v-col :class="`${candidate.rejected ? 'text--red' : ''}`">
            <span>{{ candidate.aon_cls }}%</span><br>
            <span>AON Num</span>
          </v-col>
          <v-col>
            <span>{{ candidate.aon_ind }}%</span><br>
            <span>AON Ind</span>
          </v-col>
        </v-row>
      </div>

      <div class="pt-4">
        <h4 class="text-left mb-3 mt-4">AON Report</h4>
        <div class="px-4 text-left">
          <v-skeleton-loader 
            width="100" 
            height="20" 
            type="button" 
            v-if="aonReportStatus === 'loading'"
          ></v-skeleton-loader>
          <v-btn 
            v-else-if="aonReportStatus === 'loaded' && aonReportLink !== null" 
            color="primary"
            rounded
            :href="aonReportLink"
            target="_blank"
          >
            Jetzt ansehen 
            <v-icon
              dark
              right
            >
              mdi-open-in-new
            </v-icon>
          </v-btn>
          <em v-else-if="aonReportStatus === 'error'">
            Fehler bei der Anfrage
          </em>
          <em v-else>
            Noch nicht verfügbar
          </em>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ResortController from '@/controllers/resort-controller'
import RecruiterController from '@/controllers/recruiter-controller'
import CandidateController from '@/controllers/candidate-controller'

export default {
  async beforeMount() {
    await this.getResorts()
    this.getAonReport()
  },
  props: {
    candidate: Object,
  },
  data() {
    return {
      processStatus: [0, 1, 2, 3, 4, 5, 6, 7],
      resorts: [],

      aonReportLink: null,
      aonReportStatus: 'loading',
    }
  },
  computed: {
    candidateID(){
      return this.candidate ? this.candidate.id : -1
    },
    modifiedResorts() {
      return this.resorts.map(resort => {
        if ([2, 4, 5, 6, 7].includes(resort.id) ) {
          return { ...resort, name: `${resort.name} (Vergeben)` }
        }
        return resort
      })
    }
  },
  methods: {
    async getAonReport() {
      this.aonReportLink = null
      this.aonReportStatus = 'loading'
      
      try {
        const response = await RecruiterController.aonReportUrl(this.candidate)
        this.aonReportLink = response.data.url
        this.aonReportStatus = 'loaded'
      } catch (error) {
        console.warn('Could not load AON report link', error)
        this.aonReportLink = null
        this.aonReportStatus = 'error'
      }
    },
    async getResorts() {
      try {
        const response = await ResortController.getResorts()
        this.resorts = []
        this.resorts = response.data
      } catch (error) {
        console.log(error)
      }
    },
    async changeUserDetails() {
      try {
        await CandidateController.patchCandiate(
          this.candidate.id,
          this.candidate.rating,
          this.candidate.docs_uploaded,
          this.candidate.process_status,
          this.candidate.appointment,
          this.candidate.resorts
        )
      } catch (error) {
        console.log(error)
      }
    },
    processStatusToInt(str) {
      switch (str) {
      case 'AON Assessment':
        return 0
      case 'Online Interview':
        return 1
      case 'Einladung Videointerview':
        return 3
      case 'Durchgeführtes Video-Interview':
        return 4
      case 'Einladung Assessment':
        return 5
      case 'Zusage Assessment':
        return 6
      case 'Rejected':
        return 7
      default:
        return 2
      }
    },
    processStatusToStr(num) {
      switch (num) {
      case 0:
        return 'AON Assessment'
      case 1:
        return 'Online Interview'
      case 3:
        return 'Einladung Videointerview'
      case 4:
        return 'Durchgeführtes Video-Interview'
      case 5:
        return 'Einladung Assessment'
      case 6:
        return 'Zusage Assessment'
      case 7:
        return 'Rejected'
      default:
        return 'Offline Assessment'
      }
    },
    getStatusColor(status) {
      return this.candidate[status] ? 'cyan' : 'grey'
    }
    //   getScoreColor: Utils.getScoreColor,
  },
  watch: {
    candidateID(){
      this.getAonReport()
    }
  }
}
</script>

<style>
.btn-justify-left .v-btn__content {
  justify-content: left;
}

.flip-icon {
  transform: rotate(90deg) scaleX(-1);
}
</style>

<style scoped>
.favstar, .highlight {
  position: absolute !important;
  top: -10px;
}
.text-xs{
  font-size: 12px;
}
.favstar {
  left: 30px;
}

.highlight {
  right: 30px;
}
</style>
