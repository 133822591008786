import Axios from '@/plugins/axios'

const BASE = process.env.VUE_APP_BACKEND_URL + '/recruiter'

export default {
  async logout() {
    return Axios.post(`${BASE}/logout`)
  },

  async report() {
    return Axios.get(`${BASE}/report`)
  },

  async aonReportUrl(user) {
    return Axios.get(`${BASE}/candidate/aon-report-url/${user.id}`)
  },

  async getFileAsRecruiter(user, folder, file) {
    return Axios.get(`${BASE}/candidate/${user.id}/${folder}/${file}`)
  },

  // @todo this is just a workaround, remove later
  getFileAsRecruiterLink(user, folder, file) {
    return `${BASE}/candidate/${user.id}/${folder}/${file}`
  },

  async postFilesAsRecruiter(request, user) {
    return Axios.post(`${BASE}/candidate/${user.id}/recruiterFiles`, request)
  }
}
