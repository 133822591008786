<template>
  <v-app-bar
    app
    height="108"
    class="ergo-app-bar"
    elevation="0"
  >
    <div class="w-full d-flex justify-center align-center">
      <div class="max-width w-full d-flex justify-space-between align-center align-content-center">
          <a 
            v-if="link"
            class="d-flex align-center align-content-center justify-center"
            href="https://kandidat.ergo.flynne.de"
            target="_blank"
          >
            <ergo-logo class="ergo-logo" />
          </a>
          <ergo-logo v-else class="ergo-logo"  />

          <slot></slot>
        </div>
    </div>
  </v-app-bar>
</template>
<script>
import ErgoLogo from './ErgoLogo'

export default {
  name: 'navBar',
  props: {
    link: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    getImgUrl(img) {
      return require(`@/assets/images/${img}`)
    },
  },
  components: {
    ErgoLogo,
  }
}
</script>

<style lang="scss" scoped>
.ergo-logo {
  width: 123px;
  height: 95px;
}
</style>
