import Axios from '@/plugins/axios'

const BASE = process.env.VUE_APP_BACKEND_URL ||  'http://localhost:8000'

export default {
  async renewCsrfCookie() {
    return Axios.get(`${BASE}/sanctum/csrf-cookie`)
  },
  async login({ email, password } = {}) {
    if ( !email ) return Axios.post(`${BASE}/login`)
    else return Axios.post(`${BASE}/login`, { email, password })
  },
  async loginWithCookie() {
    return Axios.post(`${BASE}/login`)
  },

  async attemptRecruiterAuthByCookie() {
    return false
  },

  async attemptCandidateAuthByCookie() {
    return false
  },
}
