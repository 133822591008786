<template>
  <v-card class="rounded-lg on-printer" style="overflow:auto">
    <div>
      <v-alert 
        v-if="candidate.rejected" 
        color="orange"
        type="warning"
      >
        Diese Person hat aufgrund Ihres AON-Scores eine Absage erhalten.
      </v-alert>
    </div>
    <v-card-text class="pa-0 d-flex flex-row align-stretch" v-if="candidate">
      <div class="px-4 pb-8 pt-10" style="width: 380px">
        <f-candidate-details-left-column :candidate="candidate">
        </f-candidate-details-left-column>
      </div>
      <div>
        <v-divider vertical></v-divider>
      </div>
      <div class="right-side flex-column" style="width:100%">
        <template>
          <v-tabs v-model="tab" centered>
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab href="#tab-files" active-class="black--text">Dateien</v-tab>
            <v-tab href="#tab-survey" active-class="black--text">Survey</v-tab>
            <!-- <v-tab href="#tab-offlinescores" active-class="black--text">Interview</v-tab> -->
            <v-tab href="#tab-notes" active-class="black--text">Kommentare</v-tab>
            <v-tab href="#tab-history" active-class="black--text">History</v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab" style="overflow-y:auto; overflow-x: hidden;">
            <v-tab-item value="tab-files">
              <f-file-tab
                  v-on:reload="$emit('reload')"
                  v-bind.sync="$props"></f-file-tab
              >
            </v-tab-item>
            <v-tab-item value="tab-survey">
              <f-survey-tab v-bind="$props"></f-survey-tab>
            </v-tab-item>
            <v-tab-item value="tab-offlinescores">
              <f-offlinescores v-bind="$props"></f-offlinescores>
            </v-tab-item>
            <v-tab-item value="tab-notes">
              <f-notes-tab v-bind="$props" ref="notesTab"></f-notes-tab>
            </v-tab-item>
            <v-tab-item value="tab-history">
              <f-history v-bind="$props"></f-history>
            </v-tab-item>
          </v-tabs-items>
        </template>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>

import CandidateDetailsLeftColumn from './CandidateDetailsLeftColumn'
import SurveyTab from './Tabs/SurveyTab'
import NotesTab from './Tabs/CommentTab'
import OfflineScoresTab from './Tabs/OfflineScoresTab'
import History from './Tabs/History'
import FileTab from './Tabs/FileTab'

export default {
  mounted() {
  },
  data() {
    return {
      tab: 'tab-details',
      tabs: [
        'tab-details',
        'tab-survey',
        'tab-notes',
        'tab-aon',
        'tab-offlinescores'
      ]
    }
  },
  props: {
    candidate: Object
  },
  computed: {},
  methods: {
  },
  components: {
    'f-candidate-details-left-column': CandidateDetailsLeftColumn,
    'f-file-tab': FileTab,
    'f-survey-tab': SurveyTab,
    'f-notes-tab': NotesTab,
    'f-offlinescores': OfflineScoresTab,
    'f-history': History
  },
  watch: {
  }
}
</script>

<style scoped>
a:link.v-tab {
  text-decoration: none !important;
}
</style>
