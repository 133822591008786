import { unfiff } from '@/service/AccessHelpers'
import axios from 'axios'

const ROUTE_MAP = {
  password: () => '/password/change',
  profile: () => '/profile/updateajax',
  companyProfile: () => '/partner/updateajax',
  hide: (job, cand) => `job/show/${job}/candidate/${cand}/hide`,
  unhide: (job, cand) => `job/show/${job}/candidate/${cand}/unhide`,
  updateOfflineScores: (cand) => `candidates/${cand}/offlinescores/update`,
  invite: (job, cand) => `job/show/${job}/sendInvite/${cand}`,
  addComment: (job, cand) => `job/show/${job}/candidate/${cand}/comment/add`,
  deleteComment: (job, cand) => `job/show/${job}/candidate/${cand}/comment/delete`,
  addJob: () => 'job/cåreate',
  deleteJob: () => 'job/delete',
  autoMailer: () => '/automailer/patch',
  emailTemplate: (job) => `/emailtemplate/patch/${job}`
}

function request (routeSlug, payload, method, ...params) {
  const route = ROUTE_MAP[routeSlug](...params)
  const result = axios[method](route, payload)
    .then(response => {
      console.log(route, 'responded with', response)

      return unfiff(response, true, 'data')
    }).catch(error => {
      console.warn(method, 'to route', route, 'with payload', payload, 'failed:', error)
      const msg = parseLaravelError(
        unfiff(
          error,
          {
            message: 'Internal error',
            errors: {}
          },
          'response', 'data'
        )
      )

      throw new Error(msg)
    })

  return result
}

function parseLaravelError (response) {
  console.log('received error', response)

  let msg = `<h4>${response.message}</h4><ul>`
  const safeObj = response.errors ? response.errors : {}

  for (const key of Object.keys(safeObj)) {
    for (const errMsg of response.errors[key]) {
      msg += `<li>${errMsg}</li>`
    }
  }

  msg += '</ul>'

  return msg
}

function getR (routeSlug, payload, ...params) {
  return request(routeSlug, payload, 'get', ...params)
}

function post (routeSlug, payload, ...params) {
  return request(routeSlug, payload, 'post', ...params)
}

function patch (routeSlug, payload, ...params) {
  return request(routeSlug, payload, 'patch', ...params)
}

export default {
  getR,
  post,
  patch
}
