<template>
  <div style="min-height: 100vh; display: flex; flex-direction: column; justify-content: space-between">
    <v-progress-linear style="position: fixed; top: 0; z-index: 100;"
                       :value="(progress.question+1) * (100 / questions.length)"></v-progress-linear>

    <nav-bar></nav-bar>

    <landing-image class="d-md-block d-none" style="margin-top: 80px"/>

    <v-container class="mt-md-0 mt-16 pt-0 max-width" fluid style="position: relative;">
      <div v-if="questions.length > 0">
        <!--SECTION QUESTION-->
        <v-row>
          <v-col cols="12" class="col-question">
            <div class="d-flex card justify-content">
              <v-row>
                <!--                <v-col class="d-none d-md-block" cols="2"-->
                <!--                       :style="`background-color: ${colors.ergodarkred}; margin: -25px 25px -25px -25px`">-->
                <!--                  <img height="100" width="100" style="padding-left: 7px" :src="getImgUrl('ergo_lightbulb.svg')" alt="Question">-->
                <!--                </v-col>-->
                <v-col class="d-flex justify-center align-center">
                  <div :style="`color: ${colors.ergodarkred}`"
                       style="font-size: large; font-weight: bold">
                    {{ questions[progress.question].text }}
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
        <!--SECTION ANSWERS-->
        <transition :name="directionForward ? 'custom-slide' : 'custom-slide-backward'" mode="out-in">
          <div :key="progress.question" style="backface-visibility: hidden">
            <template v-if="questions[progress.question].type === 0">
              <v-row class="mt-5" style="padding-left: 15px; padding-right: 10px">
                <v-col class="py-1 py-md-3" md="6" cols="12"
                       v-for="(answer, index) in questions[progress.question].answers"
                       :key="index"
                       @click="selectedAnswer = index">
                  <div :class="{'selectioncard': !(selectedAnswer-index),'answerCard': true, 'hovercard':true}">
                    <p style="height: 100%; width: 100%; text-align: center; margin-bottom: 0; margin-top: 0">{{
                        answer.text
                      }}</p>
                  </div>
                </v-col>
              </v-row>
            </template>
            <template v-if="questions[progress.question].type === 1">
              <v-row class="mt-5" style="padding-left: 15px; padding-right: 10px">
                <v-col class="py-1 py-md-3" md="6" cols="12"
                       v-for="(answer, index) in questions[progress.question].answers"
                       :key="index"
                       @click="selectMultiAnswer(index)">
                  <div
                      :class="{'selectioncard': selectedMultiAnswer.includes(index),'answerCard': true, 'hovercard':true}">
                    <p style="height: 100%; width: 100%; text-align: center; margin-bottom: 0; margin-top: 0">{{
                        answer.text
                      }}</p>
                  </div>
                </v-col>
              </v-row>
            </template>
          </div>
        </transition>
        <v-row class="mt-5">
          <div class="btnSpacer"></div>
<!--          <div class="btnBackground"></div>-->
          <button @click="clickBtnPrevious()" :style="{display:  progress.question===0 ? 'none': 'block'}"
                  class="btn-prev mb-5">
            Zurück
          </button>
          <v-spacer></v-spacer>
          <button @click="clickBtnNext()" :disabled="selectedAnswer===-1 && selectedMultiAnswer.length === 0"
                  class="btn-next mb-5">
            {{ progress.question === questions.length - 1 ? 'Fertig' : 'Nächste Frage' }}
          </button>
        </v-row>
      </div>
    </v-container>
    
    <ergo-footer />
  </div>
</template>

<script>
import ErgoFooter from '@/components/shared/ErgoFooter'
import NavBar from '@/components/shared/NavBar'
import colors from '@/utils/colors'
import router from '@/router'
import LandingImage from '@/components/landingpage/LandingImage'
import QuestionController from '@/controllers/question-controller'
import CandidateController from '@/controllers/candidate-controller'

export default {
  name: 'SurveyPage',
  async beforeMount() {
    await this.loadQuestions()
    if (localStorage.getItem('progress')) {
      try {
        this.progress = JSON.parse(localStorage.getItem('progress'))
        if (this.progress.question >= this.questions.length) {
          router.push('finished')
        }
      } catch (e) {
        console.log(e)
      }
    } else {
      // If not having any progress, assume it's a fresh survey start
      await this.surveyStarted()
    }
  },
  data() {
    return {
      directionForward: false,
      progress: {question: 0, finished: false, answers: []},
      selectedAnswer: -1,
      selectedMultiAnswer: [],
      colors: colors,
      questions: [],
    }
  },
  computed: {
    questionsAnsweredSoFar(){
      return this.progress.answers.length
    }
  },
  methods: {
    async loadQuestions() {
      try {
        const response = await QuestionController.getQuestions()
        this.questions = response.data
      } catch (error) {
        console.log(error)
      }
    },
    async surveyStarted() {
      try {
        localStorage.setItem('surveyStarted', 'true')
        const response = await CandidateController.surveyStarted()
        if (!response.data.ok){
          console.warn('survey-started did not return ok.')
        }
      } catch (err) {
        console.warn('survey-started did not respond as expected.\n', err)
      }
    },
    async nextQuestionCalled() {
      try {
        const response = await CandidateController.nextQuestion(this.questionsAnsweredSoFar)
        if (!response.data.ok){
          console.warn('next-question did not return ok.')
        }
        // Matomo Event Tracking - next_question
        window._paq.push(['trackEvent', 'Next Question', 'next', 'nextQuestion'])

      } catch (err) {
        console.warn('next-question did not respond as expected.\n', err)
      }

      // Tag Manager Tracking
      this.$gtag.event('survey_next')
    },
    getImgUrl(img) {
      return require(`@/assets/images/${img}`)
    },
    selectMultiAnswer(index) {
      if (this.selectedMultiAnswer.includes(index)) {
        this.selectedMultiAnswer.splice(this.selectedMultiAnswer.indexOf(index), 1)
      } else {
        this.selectedMultiAnswer.push(index)
      }
    },
    clickBtnNext() {
      this.directionForward = true
      if (this.questions[this.progress.question].type === 0) {
        this.progress.answers.push({
          question: this.progress.question,
          answer: this.selectedAnswer,
          answerID: this.questions[this.progress.question].answers[this.selectedAnswer].id,
          elemination: this.questions[this.progress.question].answers[this.selectedAnswer].elemination
        })
      }
      if (this.questions[this.progress.question].type === 1) {
        for (const answer of this.selectedMultiAnswer) {
          this.progress.answers.push({
            question: this.progress.question,
            answer: answer,
            answerID: this.questions[this.progress.question].answers[answer].id,
            elemination: this.questions[this.progress.question].answers[answer].elemination
          })
        }
      }
      this.progress.question++
      this.selectedMultiAnswer = []
      this.selectedAnswer = -1
      if (this.progress.question >= this.questions.length) {
        this.progress.finished = true
        router.push('finished')
      }
      const parsed = JSON.stringify(this.progress)
      localStorage.setItem('progress', parsed)

      // Tracking
      this.nextQuestionCalled()
    },
    clickBtnPrevious() {
      this.directionForward = false
      this.progress.question--
      const restored = this.progress.answers.filter(answer => {
        return answer.question === this.progress.question
      })
      if (this.questions[this.progress.question].type === 0) {
        for (const answer of restored) {
          this.selectedAnswer = answer.answer
        }
      }
      if (this.questions[this.progress.question].type === 1) {
        for (const answer of restored) {
          this.selectedMultiAnswer.push(answer.answer)
        }
      }
      this.progress.answers = this.progress.answers.filter(answer => {
        return answer.question < this.progress.question
      })
      const parsed = JSON.stringify(this.progress)
      localStorage.setItem('progress', parsed)
    }
  },
  components: {
    NavBar,
    LandingImage,
    ErgoFooter,
  }
}
</script>

<style scoped lang="scss">
@import "~@/components/shared/shared.scss";

.custom-slide-enter-active {
  animation: slide-in .2s;
}

.custom-slide-leave-active {
  animation: slide-out .2s;
}

.custom-slide-backward-enter-active {
  animation: slide-out .1s alternate-reverse;
}

.custom-slide-backward-leave-active {
  animation: slide-in .1s alternate-reverse;
}

@keyframes slide-out {
  0% {
    //position: absolute;
    opacity: 1;
    transform: translateX(0px);
  }
  100% {
    //position: absolute;
    opacity: 0;
    transform: translateX(-50px);
  }
}

@keyframes slide-in {
  0% {
    opacity: 0;
    transform: translateX(50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

@media only screen and (min-width: 960px) {
  .footer {
    position: relative;
    height: 64px;
  }
}

.footer a {
  color: white;
}

.footer a:not(:first-child) {
  padding-left: 16px;
  border-left: 1px solid #fff;
}

.navbar-text {
  font-size: smaller;
}

@media only screen and (min-width: 960px) {
  .navbar-text {
    font-size: medium;
  }
}

.btnSpacer {
  height: 70px;
}

@media only screen and (min-width: 960px) {
  .btnSpacer {
    display: none;
  }
}

.btnBackground {
  position: fixed;
  top: calc(100vh - 116px);
  height: 80px;
  width: 100%;
  background-color: white;
  box-shadow: 3px 3px 6px 0 #ccc, 8px 2px 12px -4px #ccc, 0 0 4px 0 #ccc;
}

@media only screen and (min-width: 960px) {
  .btnBackground {
    display: none;
  }
}

.col-question {
  padding: 30px 10px 10px 15px;
}

@media only screen and (min-width: 960px) {
  .col-question {
    padding: 0 10px 10px 15px;
  }
}

.btn-next {
  position: sticky; //fixed;
  right: 3px;
  width: 40%;
  height: 36px;
  background-color: #8e0038;
  border-radius: 120px;
  color: white;
  z-index: 10;
  overflow: hidden;
  top: 10px; //calc(100vh - 112px);
  margin: 8px 12px 0 12px
}

@media only screen and (min-width: 960px) {
  .btn-next {
    position: sticky;
    width: 160px;
    top: 10px;
  }
}

.btn-prev {
  position: sticky; //fixed;
  width: 40%;
  height: 36px;
  border-radius: 120px;
  border: 2px solid #8e0038;
  color: #8e0038;
  z-index: 10;
  overflow: hidden;
  top: 10px; //calc(100vh - 112px);
  margin: 8px 12px 0 12px;
}

@media only screen and (min-width: 960px) {
  .btn-prev {
    position: sticky;
    width: 160px;
    top: 10px;
  }
}

@media only screen and (min-width: 960px) {
  .btn-next {
    width: 160px;
  }
}

.btn-next:disabled {
  opacity: 0.8;
  cursor: not-allowed;
}

@media only screen and (min-width: 960px) {
  .card {
    display: block;
    height: 100%;
    width: 100%;
    padding: 28px;
    overflow: hidden;
    background-color: white;
    border-radius: 5px;
    box-shadow: 3px 3px 6px 0 #ccc, 8px 2px 12px -4px #ccc, 0 0 4px 0 #ccc;
    will-change: transform;
  }
}


.answerCard {
  display: block;
  height: 100%;
  width: 100%;
  padding: 28px;
  overflow: hidden;
  background-color: white;
  border-radius: 5px;
  box-shadow: 3px 3px 6px 0 #ccc, 8px 2px 12px -4px #ccc, 0 0 4px 0 #ccc;
  will-change: auto;
}

@media only screen and (min-width: 960px) {
  .hovercard:hover {
    cursor: pointer;
    transition: all 0.2s ease;
    transform: scale(1.1);
  }
}

.selectioncard {
  background-color: #8e0038;
  color: white;
}

body {
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
}

body {
  overflow-x: hidden;
}

@media only screen and (min-width: 960px) {
  body {
    overflow-x: unset;
  }
}
</style>
