<template>
  <div style="background-color: #F4F4F4; min-height: 100vh; display: flex; flex-direction: column; justify-content: space-between">
    <!--<CookieBanner/>-->
    <v-container class="mt-0 pt-0" fluid style="max-width: 800px; position: relative;">
      <nav-bar>
        <!--        <div class="d-md-flex align-center d-none">-->
        <!--          <span>Lieber <b>ergoistisch</b> statt egoistisch</span>-->
        <!--        </div>-->
        <router-link style="text-decoration: none" :to="'survey'" class="calltoaction-small">
          <div class="btn-calltoaction-small text-center d-flex align-center">
            <div style="width: 100%" class="my-auto mx-auto"><span style="font-size: 14px; text-decoration: none">Jetzt bewerben!</span>
            </div>
          </div>
        </router-link>
      </nav-bar>
      <v-main>
        <h2 :style="{color: colors.ergodarkred}" class="mt-4">Sie haben Fragen?</h2>
        <span :style="{color: colors.ergowarmgreydarken3}">Tragen Sie einfach Ihre Kontaktdaten und Ihre Nachricht ein. Wir melden uns in Kürze bei Ihnen.</span>
        <div class="card mb-7 mt-4">
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-text-field class="mt-1 pt-1" v-model="name" :rules="nameRules" label="Name" required></v-text-field>
            <v-text-field v-model="email" :rules="emailRules" label="E-Mail" required></v-text-field>
            <v-text-field v-model="phone" :rules="phoneRules" label="Telefonnummer"></v-text-field>
            <v-textarea v-model="msg" :counter="250" :rules="msgRules" label="Nachricht" required></v-textarea>
            <!--            <v-checkbox v-model="checkbox" :rules="[v => !!v || 'You must agree to continue!']" label="Do you agree?"-->
            <!--                        required></v-checkbox>-->
            <div class="d-flex justify-end mt-6">
              <button type="button" :disabled="!valid" class="btn-submit" @click="validate">
                Absenden
              </button>
            </div>
            <!--            <v-btn color="error" class="mr-4" @click="reset">-->
            <!--              Reset Form-->
            <!--            </v-btn>-->
            <!--            <v-btn color="warning" @click="resetValidation">-->
            <!--              Reset Validation-->
            <!--            </v-btn>-->
          </v-form>
          <v-row v-if="success" class="mt-2">
            <v-col style="padding: 10px 10px 10px 15px;">
              <v-alert @click="success = false" :style="{backgroundColor: colors.ergogreen, color: 'black'}">
                {{ responseText }}
              </v-alert>
            </v-col>
          </v-row>
          <v-row v-if="error" class="mt-2">
            <v-col style="padding: 10px 10px 10px 15px;">
              <v-alert @click="error = false" :style="{backgroundColor: colors.ergoorangedarken3, color: 'white'}">
                {{ responseText }}
              </v-alert>
            </v-col>
          </v-row>
        </div>
        <div class="d-flex flex-column justify-center align-center my-7">
          <img class="circleImage" :src="getImgUrl('recruiter.png')" alt="recruiter">
          <h2 class="mt-4">Katharina Pratesi</h2>
          <span style="text-align: center">Ihr direkter Ansprechpartner während des Recruitingprozesses.</span>
        </div>
      </v-main>
    </v-container>
    <v-footer class="footer">
      <a style="text-decoration: none" class="mx-2" target="_blank" rel="noopener noreferrer" href="https://www.ergo.com/de/Impressum-Anbieterkennzeichnung">Impressum</a>
      <router-link style="text-decoration: none" target="_blank" rel="noopener noreferrer" class="mx-2" :to="'privacy'">
        Datenschutz
      </router-link>    </v-footer>
  </div>
</template>

<script>
import NavBar from '@/components/shared/NavBar'
import colors from '@/utils/colors'
import CandidateController from '@/controllers/candidate-controller'

export default {
  name: 'ContactPage',
  data: () => ({
    colors: colors,
    valid: true,
    success: false,
    successText: '',
    error: false,
    errorText: '',
    name: '',
    nameRules: [
      v => !!v || 'Name muss vorhanden sein',
    ],
    email: '',
    emailRules: [
      v => !!v || 'E-Mail muss vorhanden sein',
      v => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/.test(v) || 'E-Mail muss gültig sein',
    ],
    phone: '',
    phoneRules: [
      v => /(^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$)|^(?![\s\S])/.test(v) || 'Telefonnummer muss gültig sein',
    ],
    msg: '',
    msgRules: [
      v => !!v || 'Nachricht muss vorhanden sein',
    ],
    checkbox: false,
  }),

  methods: {
    async validate() {
      const isValid = this.$refs.form.validate()
      if (isValid) {
        try {
          const response = await CandidateController.contact({
            name: this.name,
            email: this.email,
            phone: this.phone,
            message: this.msg
          })
          console.log(response)
          if (response.data.ok) {
            this.name = ''
            this.email = ''
            this.phone = ''
            this.msg = ''
            this.responseText = response.data.message
            this.success = true
            this.$refs.form.reset()
            this.$refs.form.resetValidation()
          } else {
            this.responseText = response.data.message
            this.error = true
          }
        } catch (e) {
          this.responseText = 'Es ist ein unerwarteter Fehler aufgetreten.'
          this.error = true
          console.log(e)
        }
      }
    },
    getImgUrl(img) {
      return require(`@/assets/images/${img}`)
    },
  },
  components: {
    NavBar,
  }
}
</script>

<style lang="scss">
@import "~@/components/shared/shared.scss";

.font-fedra-bold {
  font-family: "Fedra Serif A Bold";
}

div, p, a, span, .card, .card-content {
  font-family: FS Me;
}

h1, h2, h3, h4, h5, h6, .card-header {
  font-family: Fedra Serif A Bold;
}

.btn-submit {
  height: 48px;
  width: 180px;
  background-color: #8e0038;
  border-radius: 120px;
  color: white;
  z-index: 10;
  overflow: hidden;
}

.btn-submit:disabled {
  opacity: 0.8;
  cursor: not-allowed;
}

.card {
  display: block;
  padding: 28px;
  overflow: hidden;
  background-color: white;
  border-radius: 5px;
  box-shadow: 3px 3px 6px 0 #ccc, 8px 2px 12px -4px #ccc, 0 0 4px 0 #ccc;
  will-change: transform;
}

.circleImage {
  clip-path: circle(75px at 75px 75px);
  object-fit: cover;
  object-position: 7%;
  height: 150px;
  width: 150px;
}

.btn-calltoaction-small {
  width: 180px;
  height: 49px;
  background-color: #8e0038;
  border-radius: 120px;
  color: white;
  position: sticky;
  z-index: 10;
  overflow: hidden;
  top: 10px;
}

.footer {
  position: relative;
  width: 100%;
  bottom: 0;
  background-color: #8e0038 !important;
  height: 36px;
  line-height: 16px;
  margin-top: 16px;
  justify-content: center;
  box-shadow: 3px 3px 6px 0 #ccc, 8px 2px 12px -4px #ccc, 0 0 4px 0 #ccc !important;
}

@media only screen and (min-width: 960px) {
  .footer {
    position: relative;
    height: 64px;
  }
}

.footer a {
  color: white !important;
}

.footer a:not(:first-child) {
  padding-left: 16px;
  border-left: 1px solid #fff;
}
</style>
