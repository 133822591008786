<template>
  <v-main class="candidate-main">
    <v-container class="mt-0 candidate-container" fluid>
        <nav-bar>
          <div class="d-md-flex align-center d-none">
<!--            <span>Lieber <b>ergoistisch</b> statt egoistisch</span>-->
          </div>
        </nav-bar>
        <h1 class="mb-4">404</h1>
        <p>Diese Seite scheint nicht zu existieren.</p>
    </v-container>
  </v-main>
</template>

<script>
import NavBar from '@/components/shared/NavBar'

export default {
  components: {
    NavBar
  }
}
</script>
