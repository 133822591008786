<template>
  <v-main class="candidate-main">
    <v-container class="mt-0 candidate-container max-width" fluid>
        <nav-bar :link="false">
          <account-menu v-if="isDashboard" @alert="showAlert"></account-menu>
        </nav-bar>

        <v-expand-transition>
          <v-alert
              ref="alert"
              :type="messageType"
              v-show="showMessage"
              class="mt-4"
          >
              <span>{{ message }}</span>
              <v-icon @click="dismiss" style="float:right">
                mdi-close
              </v-icon>
          </v-alert>
        </v-expand-transition>

        <transition name="fade">
          <router-view @alert="showAlert" class="mt-3"></router-view>
        </transition>
    </v-container>
  </v-main>
</template>

<script>
import NavBar from '@/components/shared/NavBar'
import AccountMenu from './AccountMenu'

export default {
  data() {
    return {
      messageType: 'info',
      message: '',
      showMessage: false
    }
  },
  computed: {
    isDashboard(){
      return this.$route.name === 'candidateDashboard'
    }
  },
  methods: {
    showAlert(type, msg){
      this.messageType = type
      this.message     = msg
      this.showMessage = true

      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    },
    dismiss(){
      this.showMessage = false
    }
  },
  components: {
    NavBar,
    AccountMenu,
  }
}
</script>

<style lang="scss">
@import "~@/components/shared/shared.scss";

.candidate-main {
  background: rgb(244, 244, 244)
}

.candidate-container {
  position: relative;
}
</style>