<template>
  <div
      style="min-height: 100vh; display: flex; flex-direction: column; justify-content: space-between">

    <nav-bar></nav-bar>
    
    <landing-image class="d-md-block d-none" style="margin-top: 80px"/>
    
    <v-container class="mt-md-0 mt-16 pt-0 mb-7 max-width" fluid style=" position: relative;">
      <div>
        <v-row>
          <v-col cols="12" class="col-ending">
            <div class="d-flex card justify-content">
              <v-row>
                <!--                <v-col class="d-none d-md-block" cols="2"-->
                <!--                       :style="{backgroundColor: colors.ergodarkred, margin: '-25px', display: 'flex !important', alignContent: 'center', justifyContent: 'center'}">-->
                <!--                  <img height="100" width="100" style="padding-left: 7px;" class="mt-auto mb-auto" :src="getImgUrl('ergo_lightbulb.svg')" alt="Question">-->
                <!--                </v-col>-->
                <v-col class="d-flex  align-center">
                  <div :style="`color: ${colors.ergodarkred}`"
                       style="font-size: large; font-weight: bold">
                    <span v-if="!ko">
                      Wir würden dich gerne kennenlernen!<br>
                      Bitte gib deinen Namen und E-Mail-Adresse an, damit wir dich erreichen können.<br>
                      Wir melden uns zeitnah bei dir.
                    </span>
                    <span v-else>
                      Schade, das hat leider nicht geklappt.
                      Danke für deine Teilnahme.<br>
                      Vielleicht wartet der passende Job in unserem <b>ERGO</b> Karriereportal.<br>
                      Schau gerne vorbei.
                    </span>
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
        <v-row v-if="ko">
          <v-col class="btn-karriere-container">
            <div>
              <div>
                <a href="https://www.ergo.com/de/Karriere/" target="_blank">
                  <button class="btn-karriere"><b>ERGO</b> Karriereportal</button>
                </a>
              </div>
              <div class="d-flex justify-center">
                <span class="text-caption text--secondary">Link führt zu externer Seite</span>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-form v-if="!ko && !mailSend" ref="form" v-model="valid" lazy-validation>
          <v-row>
            <v-col md="6" cols="12" class="topform">
              <v-text-field
                  label="Vorname"
                  v-model="firstName"
                  :rules="nameRules"
                  :disabled="loading"
                  solo
                  :error="firstNameErrorState"
                  required
                  @keyup.enter="btnSendClick"
              ></v-text-field>
            </v-col>
            <v-col md="6" cols="12" class="topform">
              <v-text-field
                  label="Nachname"
                  v-model="lastName"
                  :rules="nameRules"
                  :disabled="loading"
                  solo
                  :error="lastNameErrorState"
                  required
                  @keyup.enter="btnSendClick"
              ></v-text-field>
            </v-col>
            <v-col md="12" cols="12" style="padding: 0 10px 10px 15px;">
              <v-text-field
                  v-model="email"
                  :rules="emailRules"
                  label="E-Mail"
                  :disabled="loading"
                  :error="emailErrorState"
                  solo
                  required
                  @keyup.enter="btnSendClick"
              ></v-text-field>
            </v-col>
            <v-col md="9" cols="12" style="padding: 0px 10px 10px 15px;">
              <!--            <span>Mit der Anmeldung zu unserem Traineeprogramm stimmen Sie unserer <a style="text-decoration: none" href="https://flynne.de/privacy/">Datenschutzerklärung</a> zu.</span>-->
              <p v-if="!ko"> 
                So geht es weiter:
                <ul>
                  <li>Du erhältst eine Einladung zu einem ca. 60-minütigen Online-Test.</li>
                  <li>Nach erfolgreichem Test werden deine Unterlagen geprüft.</li>
                  <li>Wenn du die Anforderungskriterien erfüllst, freuen wir uns darauf, dich in einem Video-Interview persönlich kennenzulernen.</li>
                  <li>Im letzten Schritt laden wir dich zu einem virtuellen Assessment Center ein. Unser Feedback erhältst du anschließend innerhalb von wenigen Tagen.</li>
                </ul>
              </p>
              
              <span>
                Die
                <router-link style="text-decoration: none" target="_blank" rel="noopener noreferrer" :to="'privacy'">
                  Datenschutzhinweise
                </router-link> habe ich zur Kenntnis genommen. Mit dem Abschicken meiner Daten willige ich ein, dass alle Daten,
                die ich im Rahmen meiner Bewerbung angebe, ausschließlich für das Auswahlverfahren des Traineeprogramms ERGO RISE verarbeitet werden.
              </span>
            </v-col>
            <v-col md="3" cols="12" class="d-flex justify-end btn-container">
              <button
                  @click.prevent="btnSendClick"
                  class="btn-send"
                  disabled
              >
                Abschicken
              </button>
            </v-col>
          </v-row>
        </v-form>
        <v-row v-if="!ko && mailSend">
          <v-col style="  padding: 30px 10px 10px 15px;">
            <v-alert :style="{backgroundColor: colors.ergogreen, color: 'black'}">
              Deine E-Mail wurde verschickt. <strong>Weitere Informationen</strong> findest du in der E-Mail.
            </v-alert>
          </v-col>
        </v-row>
        <v-row v-if="error">
          <v-col style="padding: 10px 10px 10px 15px;">
            <v-alert @click="error=false" :style="{backgroundColor: colors.ergoorangedarken3, color: 'white'}">
              {{ errorText }}
            </v-alert>
          </v-col>
        </v-row>
      </div>
    </v-container>
    <ergo-footer />
  </div>
</template>

<script>
import ErgoFooter from '@/components/shared/ErgoFooter'
import router from '@/router'
import NavBar from '@/components/shared/NavBar'
import colors from '@/utils/colors'
import LandingImage from '@/components/landingpage/LandingImage'
import CandidateController from '@/controllers/candidate-controller'

export default {
  name: 'SurveyEndPage',
  mounted() {
    if (localStorage.mailSend) {
      this.mailSend = true
    }
    if (localStorage.getItem('progress')) {
      try {
        this.progress = JSON.parse(localStorage.getItem('progress'))
        for (const answers of this.progress.answers) {
          this.ko = this.ko || answers.elemination
        }
        if (!this.progress.finished) {
          router.push('survey')
        }

        // From this point we can assume the survey was finished
        if (localStorage.getItem('surveyStarted') === 'true') {
          this.surveyDone()
          if (this.ko){
            this.surveyFailed()
          } else {
            this.surveySuccessful()
          }
          localStorage.setItem('surveyStarted', 'false')
        }
      } catch (e) {
        console.log(e)
      }
    } else {
      router.push('/')
    }
  },
  data() {
    return {
      valid: true,
      firstNameErrorState: false,
      lastNameErrorState: false,
      emailErrorState: false,
      error: false,
      errorText: '',
      loading: false,
      mailSend: false,
      ko: false,
      progress: {question: 0, finished: true, answers: []},
      colors: colors,
      mailRegex: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      email: '',
      emailRules: [
        v => !!v || 'E-Mail muss vorhanden sein',
        v => this.mailRegex.test(v) || 'E-Mail muss gültig sein',
      ],
      firstName: '',
      lastName: '',
      nameRules: [
        v => !!v || 'Name muss vorhanden sein',
      ],
    }
  },
  methods: {
    async btnSendClick() {
      this.error = false
      const isValid = this.$refs.form.validate()
      if (isValid) {
        try {
          this.loading = true
          const response = await CandidateController.create(this.email, this.progress, this.firstName, this.lastName)
          if (response.data.ok) {
            localStorage.mailSend = true
            this.mailSend = true
            this.error = false
            this.loading = false

            // Tag Manager Tracking
            this.$gtag.event('survey_submit', {
              email: this.email,
              progress: this.progress,
              firstName: this.firstName,
              lastName: this.lastName
            })
          } else {
            this.error = true
            this.errorText = response.data.message
            this.loading = false
          }
        } catch (error) {
          this.error = true
          this.loading = false
          this.errorText = 'Es ist ein unerwarteter Fehler aufgetreten.'
        }
      }
    },
    async surveyDone() {
      console.log('The survey was done.')
      try {
        const response = await CandidateController.surveyDone({
          responses: this.progress
        })
        if (!response.data.ok){
          console.warn('survey-done did not return ok.')
        }

        // Matomo Event Tracking - survey_finished
        window._paq.push(['trackEvent', 'Survey Finished', 'sFinished', 'surveyFinished'])

      } catch (err) {
        console.warn('survey-done did not respond as expected.\n', err)
      }

      // Tag Manager Tracking
      this.$gtag.event('survey_end')
    },
    async surveySuccessful() {
      console.log('The survey was successful.')
      try {
        const response = await CandidateController.surveySuccessful({
          responses: this.progress
        })
        if (!response.data.ok){
          console.warn('survey-successful did not return ok.')
        }
      } catch (err) {
        console.warn('survey-successful did not respond as expected.\n', err)
      }
    },
    async surveyFailed() {
      console.log('The survey failed.')
      try {
        const response = await CandidateController.surveyFailed({
          responses: this.progress
        })
        if (!response.data.ok){
          console.warn('survey-failed did not return ok.')
        }

        // Matomo Event Tracking - survey_failed
        window._paq.push(['trackEvent', 'Survey Failed', 'sFailed', 'surveyFailed'])

      } catch (err) {
        console.warn('survey-failed did not respond as expected.\n', err)
      }

      // Tag Manager Tracking
      this.$gtag.event('survey_failed', {
        email: this.email,
        progress: this.progress,
        firstName: this.firstName,
        lastName: this.lastName
      })
    },
    getImgUrl(img) {
      return require(`@/assets/images/${img}`)
    },
  },
  components: {
    LandingImage,
    NavBar,
    ErgoFooter,
  }
}
</script>

<style scoped lang="scss">
@import "~@/components/shared/shared.scss";

.topform {
  padding: 0 10px 0 15px;
}

.topform:first-of-type {
  padding: 30px 10px 0 15px;
}

@media only screen and (min-width: 960px) {
  .topform {
    padding: 30px 10px 0 15px;
  }
}

.footer {
  position: relative;
  width: 100%;
  background-color: #8e0038 !important;
  height: 36px;
  line-height: 16px;
  margin-top: 16px;
  justify-content: center;
  box-shadow: 3px 3px 6px 0 #ccc, 8px 2px 12px -4px #ccc, 0 0 4px 0 #ccc !important;
}

@media only screen and (min-width: 960px) {
  .footer {
    position: relative;
    height: 64px;
  }
}

.footer a {
  color: white;
}

.footer a:not(:first-child) {
  padding-left: 16px;
  border-left: 1px solid #fff;
}

.col-ending {
  padding: 35px 10px 10px 15px;
}

@media only screen and (min-width: 960px) {
  .col-ending {
    padding: 0 10px 10px 15px;
  }
}

@media only screen and (min-width: 960px) {
  .card {
    display: block;
    height: 100%;
    width: 100%;
    padding: 28px;
    overflow: hidden;
    background-color: white;
    border-radius: 5px;
    box-shadow: 3px 3px 6px 0 #ccc, 8px 2px 12px -4px #ccc, 0 0 4px 0 #ccc;
    will-change: transform;
  }
}

.btn-send {
  height: 48px;
  background-color: #8e0038;
  border-radius: 120px;
  color: white;
  z-index: 10;
  overflow: hidden;
  width: 100%;
}

@media only screen and (min-width: 960px) {
  .btn-send {
    width: 160px;
  }
}

.btn-karriere {
  height: 48px;
  background-color: #8e0038;
  border-radius: 120px;
  color: white;
  z-index: 10;
  overflow: hidden;
  width: 100%;
}

@media only screen and (min-width: 960px) {
  .btn-karriere {
    position: relative;;
    margin-left: auto;
    margin-right: auto;
    width: 240px;
  }
}

@media only screen and (min-width: 960px) {
  .btn-karriere-container {
    display: flex;
    justify-content: right;
  }
}

.btn-send:disabled {
  opacity: 0.8;
  cursor: not-allowed;
}

.btn-container {
  padding: 0 10px 0 15px;
}

@media only screen and (min-width: 960px) {
  .btn-container {
    padding: 0 10px 10px 15px;
  }
}

</style>