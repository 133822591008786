<template>
  <div class="align-center d-flex">
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          text
          color="primary"
          rounded
          class="px-0"
          dense
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-account</v-icon>
          <!-- <span class="ml-2">Konto</span> -->
          <v-icon>{{ dropStateIcon }}</v-icon>
        </v-btn>
      </template>
      <v-list class="pa-0">
        <v-list-item
          v-for="(item, index) in items"
          class="pa-0"
          :key="index"
        >
          <v-list-item-title style="min-height: 0" class="text-left">
            <v-btn text block class="text-left" left large @click="item.click">
              <span class="text-left">{{ item.title }}</span>
            </v-btn>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import CandidateController from '@/controllers/candidate-controller'
import {emitNetworkError} from './utils'

export default {
  data() {
    return {
      items: [
        {
          title: 'Ausloggen',
          click: this.logout,
        }
      ]
    }
  },
  computed: {
    dropStateIcon() {
      // return 'mdi-arrow-left-drop-circle'
      return 'mdi-menu-down'
    },
  },
  methods: {
    async logout(){
      this.loading = true
      try {
        await CandidateController.logout()
        this.candidateReady = false

        this.$emit('alert', 'info', 'Sie wurden erfolgreich ausgeloggt.')
        this.$router.push({name: 'requestLogin'})
      } catch (e) {
        console.log(e)
        emitNetworkError(this)
      }

      this.loading = false
    },
  }
}
</script>