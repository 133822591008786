<template>
  <div style="position: relative;">  
    <div class="d-flex">
      <v-img
        src="@/assets/images/new-landing-page/ERGO_QuickCheck_LP_links.webp"
        position="top"
        width="50%"
        height="530"
        size="contain"
        gradient="to bottom, rgba(0,0,0,0), rgba(0,0,0,.15)"
      >
        <template v-slot:placeholder>
          <image-placeholder class="primary lighten-3"/>
        </template>
      </v-img>
      <v-img
        src="@/assets/images/new-landing-page/QuickCheck_Verona.webp"
        position="top"
        width="50%"
        height="530"
        size="contain"
        gradient="to bottom, rgba(0,0,0,0), rgba(0,0,0,.15)"
      >

        <v-container class="d-flex container-100 h-100">
          <v-row
              align="center"
              no-gutters
          >
            <v-col class="h-100">
              <v-card  flat color="transparent" >
                <v-card-text
                    class="md-over-image pl-lg-12 pl-md-12"
                >Mach jetzt den</v-card-text
                >
                <v-card-text class="lg-over-image my-lg-5 my-md-5 text-h2" >Quick-</v-card-text>
                <v-card-text
                    class="lg-over-image text-h2 pl-16 mt-lg-1"
                >Check.</v-card-text>

              </v-card>
            </v-col>
          </v-row>
        </v-container>
        <v-overlay absolute color="transparent" class="absolute-container" >

        </v-overlay>
        <template v-slot:placeholder>
          <image-placeholder class="primary lighten-4"/>
        </template>
      </v-img>
    </div>
    <div
      class="d-flex justify-center px-6 pb-4"
      style="position: absolute; bottom: 0; left: 0; width: 100%;"
    >
      <div class="max-width w-full">
        <slot></slot>
      </div>
    </div>
  </div>
</template>
<script>
import ImagePlaceholder from '@/components/landingpage/general/ImagePlaceholder'

export default {
  name: 'LandingImage',
  beforeMount() {
    this.getSettingsPerLandingpage()
  },
  data() {
    return {
      landingPicture: 'landing3432.png',
      landingPictureWebP: 'landing3432.webp'
    }
  },
  methods: {
    getImgUrl(img) {
      return require(`@/assets/images/${img}`)
    },
    getSettingsPerLandingpage() {
      let landing = this.$route.query.landing
      if (!landing) {
        landing = localStorage.landing
      }
      switch (landing) {
      // case '4693':
      //   localStorage.landing = '4693'
      //   this.landingPicture = 'landing4693.png'
      //   this.landingPictureWebP = 'landing4693.webp'
      //   // this.landingSlogan = 'Lieber <b>sinnvoll</b> statt neidvoll';
      //   break
      // case '8321':
      //   localStorage.landing = '8321'
      //   this.landingPicture = 'landing8321.png'
      //   this.landingPictureWebP = 'landing8321.webp'
      //   // this.landingSlogan =  'Lieber <b>gestalten</b> statt verwalten';
      //   break
      // case '9438':
      //   localStorage.landing = '9438'
      //   this.landingPicture = 'landing9438.png'
      //   this.landingPictureWebP = 'landing9438.webp'
      //   // this.landingSlogan =  'Lieber <b>karriere mit Sinn</b> statt Job ohne Perspektiven';
      //   break
      case '8821':
        localStorage.landing = '8821'
        this.landingPicture = 'landing8821.png'
        this.landingPictureWebP = 'landing8821.webp'
        // this.landingSlogan =  'Lieber <b>karriere mit Sinn</b> statt Job ohne Perspektiven';
        break
      // case '6363':
      //   localStorage.landing = '6363'
      //   this.landingPicture = 'landing6363.jpg'
      //   this.landingPictureWebP = 'landing6363.jpg'
      //   // this.landingSlogan =  'Lieber <b>karriere mit Sinn</b> statt Job ohne Perspektiven';
      //   break
      case '5495':
        localStorage.landing = '5495'
        this.landingPicture = 'landing5495.png'
        this.landingPictureWebP = 'landing5495.webp'
        // this.landingSlogan =  'Lieber <b>karriere mit Sinn</b> statt Job ohne Perspektiven';
        break
      case '1774':
        localStorage.landing = '1774'
        this.landingPicture = 'landing1774.png'
        this.landingPictureWebP = 'landing1774.webp'
        // this.landingSlogan =  'Lieber <b>karriere mit Sinn</b> statt Job ohne Perspektiven';
        break
      default:
      }
    }
  },
  components: {
    ImagePlaceholder
  }
}
</script>
<style scoped lang="scss">
@import '~vuetify/src/styles/styles.sass';
@import "~@/components/shared/shared.scss";

.container-100{
  height: 100%!important;
}

.lg-over-image {
  color: #8e0038!important;
  line-height: .3em;
}

.md-over-image {
  color: #8e0038!important;
  font-family: 'Fedra Serif A Light', Georgia, serif;
  font-size: 36px;
}

</style>