import Vue from 'vue'
import VueRouter from 'vue-router'

// Shared
// import NotFoundPage from '@/components/shared/NotFoundPage.vue'

// Recruiter App
import RecruiterApp from '../components/recruiter-dashboard/RecruiterApp'
import RecruiterDashboard from '../components/recruiter-dashboard/RecruiterDashboard'
// import RecruiterLogin from '../components/recruiter-dashboard/RecruiterLogin'

import Store from '../plugins/store'
import AuthController from '../controllers/auth-controller'
// import LandingPage from '@/components/landingpage/LandingPageCompainFinished'
// import LandingPageReal from '@/components/landingpage/LandingPage.vue'
import SurveyPage from '@/components/survey/SurveyPage'
import RedirectPage from '@/components/shared/RedirectPage'
import SurveyEndPage from '@/components/survey/SurveyEndPage'
import PresentationPage from '@/components/landingpage/PresentationPage'
import ContactPage from '@/components/landingpage/ContactPage'
import RecruiterLogin from '@/components/recruiter-dashboard/RecruiterLogin.vue'

// Candidate Dashboard
import CandidateApp from '@/components/candidate-dashboard/CandidateApp.vue'
import CandidateRequestLoginPage from '@/components/candidate-dashboard/RequestLoginPage.vue'
import CandidateLoginPage from '@/components/candidate-dashboard/LoginPage.vue'
import CandidateDashboardPage from '@/components/candidate-dashboard/CandidateDashboardPage.vue'
import CandidateWithdrawApplicationPage from '@/components/candidate-dashboard/CandidateWithdrawApplicationPage.vue'
import PrivacyPage from '@/components/shared/PrivacyPage'
import CandidateController from '../controllers/candidate-controller'
import NotFoundPage from '@/components/shared/NotFoundPage.vue'


Vue.use(VueRouter)

const routes = [
  // Candidate Dashboard
  {
    path: '/kandidat',
    component: CandidateApp,
    children:
      [
        {
          path: 'login',
          name: 'requestLogin',
          component: CandidateRequestLoginPage
        },
        {
          path: '',
          name: 'candidateDashboard',
          component: CandidateDashboardPage
        },
        {
          path: 'withdraw/:token',
          name: 'candidateWithdrawApplication',
          component: CandidateWithdrawApplicationPage
        },
        {
          path: 'auth/:token',
          name: 'login',
          component: CandidateLoginPage
        }
      ]
  },
  {
    path: '/recruiter',
    component: RecruiterApp,
    children: [
      {
        path: 'login',
        component: RecruiterLogin,
        name: 'recruiterLogin',
      },
      {
        path: '',
        component: RecruiterDashboard,
        name: 'recruiterDashboard',
        meta: { auth: { role: 'recruiter', elseRedirect: { name: 'recruiterLogin' } } }
      },
      // { path: 'logout',
      //   component: LogoutPage,
      //   meta: { auth: { role: 'recruiter', elseRedirect: { name: 'recruiterLogin' }}}
      // },
    ]
  },
  {
    path: '/',
    redirect: () => {
      window.location.href = 'https://kandidat.ergo.flynne.de'
      return '/redirect' // not important since redirecting
    },
  },
  // {
  //   path: '/start',
  //   name: 'landingpage',
  //   component: LandingPageReal
  // },
  {
    path: '/survey',
    name: 'survey',
    component: SurveyPage
  },
  {
    path: '/redirect',
    name: 'redirect',
    component: RedirectPage
  },
  {
    path: '/finished',
    name: 'SurveyEndPage',
    component: SurveyEndPage
  },
  {
    path: '/presentation',
    name: 'presentation',
    component: PresentationPage
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactPage
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: PrivacyPage
  },
  // Shared
  {
    path: '*',
    component: NotFoundPage
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach(async (to, from, next) => {

  if (to.meta.auth !== undefined) {

    const requiredRole = to.meta.auth.role

    if (Store.auth[requiredRole]) {
      next()
      window.gtag('event', 'page_view', {
        page_location: to.path,
        user_agent: navigator.userAgent,
      })
    } else {
      try {
        const response = await AuthController.login()
        const authorized = response.data.role === requiredRole
        console.log('response', response)
        Store.auth[requiredRole] = authorized
        next()
      } catch (error) {
        console.log('error', error)
        router.push(to.meta.auth.elseRedirect)
      }
    }
  } else {
    next()
    window.gtag('event', 'page_view', {
      page_location: to.path,
      user_agent: navigator.userAgent,
    })
  }
})

router.afterEach(async (to) => {
  try {
    const url = window.location.origin + to.path
    const response = await CandidateController.viewContent({ url: url })
    if (!response.data.ok) console.warn('view-content did not return ok')
  } catch (err) {
    console.warn('view-content did not respond as expected.\n', err)
  }
})

export default router
