export default {
  textergored: '#bf1528',
  ergodarkred: '#8e0038',
  ergoviolet: '#e4d6e4',
  ergovioletlighten: '#efe7ed',
  ergovioletdarken1: '#dbaccc',
  ergovioletdarken2: '#ce5e92',
  ergovioletdarken3: '#b31767',
  ergoiceblue: '#ccebed',
  ergoicebluelighten: '#e2f6fa',
  ergoicebluedarken1: '#a9dae0',
  ergoicebluedarken2: '#47bcc5',
  ergoicebluedarken3: '#0f94a7',
  ergogreen: '#b5d5c1',
  ergogreenlighten: '#e3f0e6',
  /* there is no darken1?! */
  ergogreendarken2: '#5fb8a2',
  ergogreendarken3: '#009284',
  ergoyellow: '#f8e89d',
  ergoyellowlighten: '#fff4b0',
  ergoyellowdarken1: '#f7db6f',
  ergoyellowdarken2: '#fad73b',
  ergoyellowdarken3: '#f6cb00',
  ergoorange: '#f7dcbb',
  ergoorangelighten: '#ffeede',
  ergoorangedarken1: '#f3c591',
  ergoorangedarken2: '#efa56d',
  ergoorangedarken3: '#e87a16',
  ergowarmgrey: '#d9d5c7',
  ergowarmgreylighten: '#ebe6d8',
  ergowarmgreydarken1: '#bbb9ad',
  ergowarmgreydarken2: '#807e6f',
  ergowarmgreydarken3: '#545241'
}