<template> 
  <v-dialog
    v-model="withdrawDialog"
    width="500"
    persistent
  >
    <v-expand-transition>
        <v-alert
            :type="withdrawMessageType"
            v-show="!!withdrawMessage"
            :prominent="withdrawMessageType === 'success'"
        >
            {{ withdrawMessage }}
        </v-alert>
    </v-expand-transition>

    <v-card>
        <v-card-title class="mb-4">
            <h5>Bewerbung zurückziehen</h5>
        </v-card-title>

        <v-card-text>
            Sind Sie sicher, dass Sie Ihre Bewerbung zurückziehen und
            alle Daten löschen möchten? Dieser Schritt kann nicht
            rückgängig gemacht werden.
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
                color="primary"
                dark
                class="my-4 px-4"
                @click="withdrawApplication"
                :loading="withdrawLoading"
                elevation="8"
                rounded
            >
                Bewerbung zurückziehen
            </v-btn>

            <v-btn
                color="primary"
                text
                @click="withdrawDialog = false, $router.push({name: 'candidateDashboard'})"
                :disabled="withdrawLoading"
                class="px-4"
                rounded
            >
                Abbrechen
            </v-btn>
        </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import CandidateController from '@/controllers/candidate-controller'

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      loading: true,

      withdrawLoading: false,
      withdrawDialog: true,

      withdrawMessage: '',
      withdrawMessageType: 'info',
    }
  },
  computed: {
    token() {
      return this.$route.params.token
    }
  },
  methods: {
    async withdrawApplication(){
      this.withdrawLoading = true
      this.withdrawMessage = ''
      

      try {
        const response = await CandidateController.withdrawApplicationByToken(this.token)
        if (response.data.message === 'Already authenticated.')
        {
          this.withdrawApplicationLoggedIn()
        } else if (!response.data.ok){
          this.withdrawMessageType = 'info'
          this.withdrawMessage = response.data.message
        } else {
          this.$emit(
            'alert',
            'info',
            'Ihre Daten wurden zur Löschung vorgemerkt und Ihre Bewerbung zurückgezogen.' 
            + ' Sie erhalten in Kürze eine Bestätigung per E-Mail.'
          )
          this.$router.push({name: 'requestLogin'})
        } 
      } catch (e) {
        this.withdrawMessageType = 'error'
        this.withdrawMessage =
          'Ein Netzwerk-Fehler ist aufgetreten. Bitte probieren Sie es erneut.'
      }

      this.withdrawLoading = false
    },
    async withdrawApplicationLoggedIn(){
      this.withdrawLoading = true

      try {
        await CandidateController.delete()
        this.$emit(
          'alert',
          'info',
          'Ihre Daten wurden zur Löschung vorgemerkt und Ihre Bewerbung zurückgezogen.' 
          + ' Sie erhalten in Kürze eine Bestätigung per E-Mail.'
        )
        this.$router.push({name: 'requestLogin'})
      } catch (e) {
        console.log(e)
        this.withdrawMessageType = 'error'
        this.withdrawMessage =
          'Ein Netzwerk-Fehler ist aufgetreten. Bitte probieren Sie es erneut.'
      }

      this.withdrawLoading = false
    }
  }
}
</script>