<template>
  <v-container v-if="false">
    <v-row>
      <v-col cols="12">
        <v-container v-for="(score, index) of scoreList" :key="index">
          <v-card
            outlined
            class="pt-5 pb-7 px-6 mb-4">
            <div class="d-flex justify-space-between" style="height: 50px;">
              <div class="d-flex align-center">
                <div v-if="score.type === 'radio'" class="my-auto">
                  <small style="color: dimgray;">{{ score.question }}</small>
                  <p>{{ score.answer }}</p>
                </div>
                <div v-else class="my-auto">
                  <span>{{ score.question }}</span>
                </div>
              </div>
              <div class="d-flex ml-auto">
                <v-container class="d-flex align-center">
                  <v-rating
                    class="my-0 py-0"
                    half-increments
                    hover
                    :length="ratingLength"
                    size="20"
                    v-model="score.value"
                    @input="insertOrUpdate($event, score.id, score.answerId)"
                  ></v-rating>
                  <!-- above used to be v-model="score.value * ratingLength" -->
                </v-container>
              </div>
            </div>
            <v-container v-for="(comment, index) of score.comments" :key="index" class="font-italic ml my-2 py-2">
              <span>{{ comment.text }}</span>
              <span class="px-2 grey--text">•</span>
              <span class="grey--text">{{ new Date(comment.updated_at).toLocaleString() }}</span>
              <!--              <span class="px-2 grey&#45;&#45;text">•</span>-->
              <span class="ml-1 grey--text" @click="deleteComment(comment.id)">
                <v-hover v-slot="{ hover }">
                  <v-icon small :color="hover ? 'red' : 'gray'">mdi-delete-outline</v-icon>
                </v-hover>
              </span>
            </v-container>
            <v-text-field
              filled
              clearable
              hide-details
              dense
              v-model="score.newComment"
              class="mt-2"
              style="position: absolute; width: 92%; background: white"
              rounded
              prepend-inner-icon="mdi-file-document-edit-outline"
              clear-icon="mdi-close"
              type="text"
              @keydown.enter.prevent="submitNewComment(score.id, score.newComment)"
            ></v-text-field>
            <span></span>
          </v-card>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios'

export default {
  props: {
    candidate: Object,
    answers: Object
  },
  mounted () {
    this.loadScores()
  },
  data () {
    return {
      ratingLength: 5,
      loading: true,
      selectType: 'Checkbox',
      selectableTypes: ['Stars', 'Checkbox'],
      // newText: '',
      scoreList: [],
      selectAnswer: '',
      selectableAnswers: [],
      rules: {
        required: value => !!value || 'Required.',
        counter: value => value.length <= 20 || 'Max 20 characters'
      }
    }
  },
  computed: {},
  methods: {
    isLoaded () {
      return true
    },
    formatDate (date) {
      const d = new Date()
      return `${d.toLocaleDateString(date)} ${d.toLocaleTimeString(date)}`
    },
    deleteComment (id) {
      const sure = window.confirm('Wirklich löschen?')
      if (sure) {
        axios.post(`admin/tsapcampaignuserscores/comment/delete/${id}`)
          .then(response => {
            this.loadScores()
            console.log(response)
          })
      }
    },
    submitNewComment (scoreid, commenttext) {
      console.log(scoreid, commenttext)
      if (commenttext) {
        axios.post('admin/tsapcampaignuserscores/comment/insert', { id: scoreid, text: commenttext })
          .then(response => {
            this.loadScores()
            console.log(response)
          })
      }
    },
    loadScores () {
      axios.get('http://localhost:8000/api/interview')
        .then(response => {
          console.log('We are the response', response.data)
          this.scoreList = []
          // for (const responseElement of response.data) {
          //   this.scoreList.push({
          //     value: responseElement.value,
          //     answer: responseElement.answer,
          //     answerId: responseElement.answerId,
          //     comments: JSON.parse(responseElement.comment),
          //     created_at: responseElement.created_at,
          //     id: responseElement.id,
          //     question: responseElement.question,
          //     questionId: responseElement.questionId,
          //     type: responseElement.type,
          //     updated_at: responseElement.updated_at,
          //     newComment: ''
          //   })
          // }
          console.log('We are the answers', this.scoreList)
        })
    },
    async insertOrUpdate (value, id, answerId) {
      const data = {
        id: id,
        answer_id: answerId,
        value: value / 5
      }
      console.log(data)
      try {
        axios.post(`/admin/tsapcampaignuserscores/insertorupdate/${this.candidate.id}`, data).then(() => {
          this.loadScores()
        })
      } catch (err) {
        console.log(err)
      }
    },
    // test () {
    //   axios.get(`/admin/tsapcampaignuserscores/get/${this.candidate.id}`)
    //     .then(response => {
    //       // this.scoreList = response.data;
    //       console.log('We are the answers', response.data)
    //     })
    // }
  }
}
</script>

<style>

</style>
