import BackendUploader from './BackendUploader'

import { format, formatDistance } from 'date-fns'
import { de, enUS } from 'date-fns/locale'

const LOCALES = { de, enUS }

function beautifulDate (date) {
  return format(
    new Date(date),
    'PPPpp',
    { locale: LOCALES[window.locale] }
  )
}

function beautifulDateTimeAgo (date) {
  return formatDistance(
    new Date(date),
    new Date(),
    { locale: LOCALES[window.locale], addSuffix: true }
  )
}

function beautifulDateInterval (date1, date2) {
  return formatDistance(
    new Date(date1),
    new Date(date2),
    { locale: LOCALES[window.locale] }
  )
}

export default {
  ratingToStr (num) {
    switch (num) {
    case 0:
      return 'A'
    case 1:
      return 'B'
    default:
      return 'C'
    }
  },
  ratingToInt (str) {
    switch (str) {
    case 'A':
      return 0
    case 'B':
      return 1
    default:
      return 2
    }
  },
  ratingToCol (num) {
    switch (num) {
    case 0:
      return '#003f5c'
    case 1:
      return '#d45087'
    default:
      return '#ff7c43'
    }
  },
  processStatusToStr (num) {
    switch (num) {
    case 0:
      return 'AON Assessment'
    case 1:
      return 'Online Interview'
    case 3:
      return 'Einladung Videointerview'
    case 4:
      return 'Durchgeführtes Video-Interview'
    case 5:
      return 'Einladung Assessment'
    case 6:
      return 'Zusage Assessment'
    case 7:
      return 'Rejected'
    default:
      return 'Offline Assessment'
    }
  },
  processStatusToInt (str) {
    switch (str) {
    case 'AON Assessment':
      return 0
    case 'Online Interview':
      return 1
    case 'Einladung Videointerview':
      return 3
    case 'Durchgeführtes Video-Interview':
      return 4
    case 'Einladung Assessment':
      return 5
    case 'Zusage Assessment':
      return 6
    case 'Rejected':
      return 7
    default:
      return 2
    }
  },
  getScoreColor (score) {
    let color = '#ffa600'

    if (score >= 30) color = '#ff7c43'
    if (score >= 40) color = '#f95d6a'
    if (score >= 50) color = '#d45087'
    if (score >= 60) color = '#a05195'
    if (score >= 70) color = '#665191'
    if (score >= 80) color = '#2f4b7c'
    if (score >= 90) color = '#003f5c'

    return color
  },

  deepCopy (obj) {
    return JSON.parse(JSON.stringify(obj))
  },

  toggleEditing (this_) {
    this_.editing = !this_.editing
    // if ('loadState' in this_) this_.loadState();

    if (
      !this_.editing && this_.alertType === 'error' ||
      this_.editing && this_.alertType === 'success'
    ) {
      this_.alertVisible = false
    }
  },

  async onSaveClick (this_, payload, i18nKey = 'general.successfullySaved', setLoadFalse = true) {
    this_.alertVisible = false

    if (!this_.formIsValid) {
      console.log('onSaveClick: form not valid.')
      return undefined
    }
    let res

    // requirements
    if (!('_route' in payload)) {
      console.warn('You forgot to add the \'_route\' key to the payload.', payload)
      return res
    }

    if (!('_params' in payload) || !payload._params) {
      payload._params = []
    }

    this_.loading = true
    const method = '_method' in payload ? payload._method : 'patch'

    try {
      if ('_formData' in payload) {
        // workaround for axios form data
        payload._formData.append('_method', 'PATCH')
        res = await BackendUploader.post(payload._route, payload._formData)
      } else {
        res = await BackendUploader[method](payload._route, payload, ...payload._params)
      }

      this_.alertMessage = `<h4>${this_.$t(i18nKey)}</h4>`
      this_.alertType = 'success'
      this_.alertVisible = true

      if ('toggleEditing' in this_) this_.toggleEditing()
    } catch (err) {
      console.err(err.message)

      this_.alertMessage = err.message
      this_.alertType = 'error'
      this_.alertVisible = true
    }

    if (setLoadFalse) this_.loading = false

    return res
  },

  async dispatchAndReload (this_, action, payload) {
    this_.$store.dispatch(action, payload)
    // this_.loadState;
  },
  beautifulDate,
  beautifulDateTimeAgo,
  beautifulDateInterval
}
