<template>
  <div class="ma-0 d-flex">
    <div
        style="
        width: 50%;
        max-width: 525px;
        min-width: 425px;
        z-index: 4;
        max-height: calc( 100vh - 80px );
        overflow:auto
      "
        class="ml-n4 fill-height mr-3 my-n4"
    >
      <div class="candidates-wrap">
        <template v-if="candidatesLoaded">
          <v-card 
            style="font-size: small" 
            class="text-center elevation-2 transition-swing mx-2 mx-lg-4 mt-3 rounded-lg"
          >
            <v-card-text>
              {{ candidates.length }} gesamt / 
              {{ filteredCandidates.length }} gefiltert
            </v-card-text>
          </v-card>
          <div class="candidate-list">
            <f-candidate-item
                v-for="( candidate, index ) in paginatedCandidates"
                :candidate="candidate"
                :selected="index === selectedCandidateIndex"
                :key="index"
                :class="index === 0 ? 'my-3' : 'mb-3'"
                @clickCandidate="selectedCandidateIndex = index"
            ></f-candidate-item>
          </div>
          <div class="mt-2">
            <v-pagination
              v-model="page"
              :length="Math.ceil(filteredCandidates.length/candidatesPerSite)"
              total-visible="6">
            </v-pagination>
          </div>
        </template>
      </div>
      
    </div>

    <div
        class="pl-0 d-flex flex-column fill-height justify-space-between on-printer-wrap"
        style="width:100%; max-height: calc( 100vh - 112px )"
    >
      <div v-if="candidatesLoaded">
        <v-container>
          <v-row>
            <v-col class="pl-0 pt-0">
              <v-text-field
                v-model="search"
                solo
                label="Suche"
                clearable
                prepend-inner-icon="mdi-magnify"
              ></v-text-field>
            </v-col>
            <v-col class="pt-0">
              <v-select
                  solo
                  v-model="filterRatingSelection"
                  :items="filterRating"
                  hint="Bewertung"
                  persistent-hint
              ></v-select>
            </v-col>
            <v-col class="pt-0">
              <v-select
                  solo
                  v-model="filterProcessStatusSelection"
                  :items="filterProcessStatus"
                  hint="Prozess Status"
                  persistent-hint
              ></v-select>
            </v-col>
            <v-col class="pt-0">
              <v-select
                  solo
                  v-model="filterDocsUploadedSelection"
                  :items="filterDocsUploaded"
                  hint="Dokument hochgeladen"
                  persistent-hint
              ></v-select>
            </v-col>
          </v-row>
        </v-container>
        <v-container>
          <v-row>
            <v-col class="pl-0 pt-0">
              <v-select
                  solo
                  v-model="filterResortSelection"
                  :items="filterResort"
                  hint="Abteilung"
                  persistent-hint
              ></v-select>
            </v-col>
            <v-col class="pr-0 pt-0">
              <v-select
                  solo
                  v-model="filterAppointmentSelection"
                  :items="filterAppointment"
                  hint="Termin"
                  persistent-hint
              ></v-select>
            </v-col>
            <v-col class="pr-0 pt-0">
              <v-select
                  solo
                  v-model="filterAonStatusSelection"
                  :items="filterAonStatus"
                  hint="Online Assessment"
                  persistent-hint
              ></v-select>
            </v-col>
            <v-col class="pr-0 pt-0">
              <v-select
                  solo
                  v-model="filterBehinderungSelection"
                  :items="filterBehinderungStatus"
                  hint="Schwerbehinderung"
                  persistent-hint
              ></v-select>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <template v-if="selectedCandidateIndex !== undefined">
        <f-candidate-details
            class="fill-height my-4"
            style="min-height: 100px;"
            v-on:reload="loadCandidates()"
            :candidate.sync="paginatedCandidates[selectedCandidateIndex]"
        ></f-candidate-details>
      </template>
      <template v-else>
        <v-card class="mt-4">
          <v-card-text>
            Kandidat auswählen
          </v-card-text>
        </v-card>
      </template>
    </div>
  </div>
</template>

<script>

import CandidateItem from '@/components/recruiter-dashboard/CandidateItem'
import CandidateDetails from '@/components/recruiter-dashboard/CandidateDetails/CandidateDetails.vue'
import CandidateController from '@/controllers/candidate-controller'

export default {
  async beforeMount() {
    await this.loadCandidates()
  },
  data() {
    return {
      search: '',
      candidatesPerSite: 15,
      candidatesLoaded: false,
      candidates: [],
      page: 1,
      selectedCandidateIndex: undefined,
      filterRating: ['Alle', 'A', 'B', 'C'],
      filterRatingSelection: 'Alle',
      filterProcessStatus: ['Alle', 'AON Assessment', 'Online Interview', 'Offline Assessment', 'Einladung Videointerview','Durchgeführtes Video-Interview', 'Einladung Assessment' ,'Zusage Assessment', 'Rejected'],
      filterProcessStatusSelection: 'Alle',
      filterDocsUploaded: ['Alle', 'Ja', 'Nein'],
      filterDocsUploadedSelection: 'Alle',
      filterResort: ['Alle', 'Schaden/ Unfall', 'Gesundheit', 'Operations', 'Vertrieb', 'IT', 'Lebensversicherung', 'Marketing/Investmentprodukte', 'Leben Klassik'],
      filterResortSelection: 'Alle',
      filterAppointment: ['Alle', '10.04','30.04','16.05','28.05','05.06','26.06','10.07','25.07','14.08' ],
      filterAppointmentSelection: 'Alle',
      filterAonStatus: ['Bestanden', 'Nicht bestanden', 'Alle'],
      filterAonStatusSelection: 'Bestanden',
      filterBehinderungStatus: ['Alle', 'Nein', 'Ja'],
      filterBehinderungSelection: 'Alle',
    }
  },
  computed: {
    filterProcessStatusSelectionId(){
      return this.filterProcessStatus.findIndex( e => 
        this.filterProcessStatusSelection === e
      ) - 1
    },
    filteredCandidates() {
      let filtered = this.candidates
      if (this.filterRatingSelection !== 'Alle') {
        filtered = filtered.filter(candidate => {
          return candidate.rating === this.ratingToInt(this.filterRatingSelection)
        })
      }
      if (this.filterProcessStatusSelection !== 'Alle') {
        filtered = filtered.filter(candidate => {
          return candidate.process_status === this.filterProcessStatusSelectionId
        })
      }
      if (this.filterDocsUploadedSelection !== 'Alle') {
        filtered = filtered.filter(candidate => {
          if (this.filterDocsUploadedSelection === 'Ja') {
            return candidate.docs_uploaded
          }
          return !candidate.docs_uploaded
        })
      }
      if (this.filterAppointmentSelection !== 'Alle') {
        filtered = filtered.filter(candidate => {
          return candidate.appointment === this.appointmentToInt(this.filterAppointmentSelection)
        })
      }
      if (this.filterResortSelection !== 'Alle') {
        filtered = filtered.filter(candidate => {
          return candidate.resorts.includes(this.resortToInt(this.filterResortSelection))
        })
      }
      if (this.filterAonStatusSelection !== 'Alle') {
        filtered = filtered.filter(candidate => {
          if (this.filterAonStatusSelection === 'Bestanden') {
            return candidate.rejected === false
          }
          return candidate.rejected === true
        })
      }

      if (this.filterBehinderungSelection !== 'Alle') {
        filtered = filtered.filter(candidate => {
          if (this.filterBehinderungSelection === 'Ja') {
            return candidate.disability === true
          }
          return candidate.disability === false
        })
      }

      const searchLC = this.search ? this.search.trim().toLowerCase() : null
      if (searchLC) {
        filtered = filtered.filter(candidate => {
          const fullName = candidate.first_name + ' ' + candidate.last_name
          return (
            candidate.first_name.toLowerCase().includes(searchLC)
            || candidate.last_name.toLowerCase().includes(searchLC)
            || fullName.toLowerCase().includes(searchLC)
            || candidate.email.toLowerCase().includes(searchLC)
          )
        })
      }
      return filtered
    },
    paginatedCandidates() {
      let paginated = this.filteredCandidates
      paginated = paginated.slice((this.page - 1) * this.candidatesPerSite, (this.page - 1) * this.candidatesPerSite + this.candidatesPerSite)
      return paginated
    }
  },
  methods: {
    async loadCandidates() {
      try {
        const response = await CandidateController.getCandidates()
        this.candidates = response.data
        this.candidates.forEach(candidate => {
          candidate.resorts = candidate.resorts.map(resort => resort.id)
        })
        // console.log('👩👨‍🦰', this.candidates)
        this.candidatesLoaded = true
      } catch (error) {
        if (error.response.status === 401) this.$router.push({name: 'recruiterLogin'})
        console.log(error)
      }
    },
    ratingToInt(str) {
      switch (str) {
      case 'A':
        return 0
      case 'B':
        return 1
      default:
        return 2
      }
    },
    appointmentToInt(str) {
      switch (str) {
      case '10.04':
        return 0
      case '30.04':
        return 1
      case '16.05':
        return 2
      case '28.05':
        return 3
      case '05.06':
        return 4
      case '26.06':
        return 5
      case '10.07':
        return 6
      case '25.07':
        return 7
      default:
        return 8
      }
    },
    resortToInt(str) {
      switch (str) {
      case 'Schaden/ Unfall':
        return 1
      case 'Gesundheit':
        return 2
      case 'Operations':
        return 3
      case 'Vertrieb':
        return 4
      case 'IT':
        return 5
      case 'Lebensversicherung':
        return 6
      case 'Marketing/Investmentprodukte':
        return 7
      default:
        return 8
      }
    },
  },
  watch: {},
  components: {
    'f-candidate-item': CandidateItem,
    'f-candidate-details': CandidateDetails
  }
}
</script>
