<template>
<div>
  <v-expand-transition>
    <div class="text-center" v-show="loading">
      <v-progress-circular
        
        indeterminate
        class="px-4 mt-5"
        color="primary"
        size="100"
        width="10"
    ></v-progress-circular>
    </div>
  </v-expand-transition>
</div>
</template>

<script>
import CandidateController from '@/controllers/candidate-controller'
import {emitNetworkError} from './utils'

export default {
  data() {
    return {
      loading: false,
      disabled: false,
    }
  },
  async mounted() {
    this.loginByToken()
  },
  computed: {
    token(){
      return this.$route.params.token
    }
  },
  methods: {
    async loginByToken(){
      this.loading = true

      try {
        const response = await CandidateController.loginByToken(this.token)
        const data = response.data

        if (data.ok) {
          this.$router.push({name: 'candidateDashboard'})
        } else {
          this.$emit('alert', 'info', data.message)
          this.$router.push({name: 'requestLogin'})
        }
      } catch (e) {
        console.log(e)
        emitNetworkError(this)
        this.$router.push({name: 'requestLogin'})
      }

      this.loading = false
    }
  },
}
</script>