<template>
  <div class="pa-4">
    <div>
      <v-checkbox
        v-model="showLoginRequests"
        label="Einmal-Login-Anfragen anzeigen"
        color="primary"
        value="true"
        hide-details
        dense
      ></v-checkbox>
    </div>

    <div class="my-4 background-primary"></div>
    
    <v-container v-if="notificationLogs && notificationLogs.length > 0">
      <v-row 
        v-for="(log, index) in notificationLogs" 
        :key="index"
        style="font-size: small;"
      >
        <v-col cols="3"><span>{{ new Date(log.send_at).toLocaleString() }}</span></v-col>
        <v-col cols="9"><span>{{ log.descriptive_name }}</span></v-col>
      </v-row>
    </v-container>

    <em v-else>
      keine E-Mails bisher verschickt
    </em>
  </div>
</template>
<script>
export default {
  data() {
    return {
      histories: [],
      showLoginRequests: false
    }
  },
  computed: {
    notificationLogs(){
      const ns = [...this.candidate.notification_logs]
      ns.sort( (n1,n2) => {
        const t1 = n1.valueOf() 
        const t2 = n2.valueOf()
        if ( t1 === t2 ) return 0
        if ( t1 > t2 ) return -1
        return 1
      })
      if (this.showLoginRequests) return ns
      return ns.filter( n => {
        return n.notification_class !== 'App\\Notifications\\LoginRequested'
      })
    }
  },
  props: {
    candidate: Object
  }
}
</script>
<style>

</style>
