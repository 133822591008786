<template>
  <v-hover v-slot="{ hover }">
    <v-card
        :class="`transition-swing elevation-${hover ? 6 : 2} mx-2 mx-lg-4 mb-3 rounded-lg ${candidate.rejected ? 'rejected' : ''}`"
        @click="onClickCard"
    >
      <!--      :to="to"-->
      <v-card-text class="px-6 py-2">
        <v-row>
          <v-col cols="8" class="d-flex align-center" style="position: relative;">
            <!--            <v-avatar size="36" class="mr-2">-->
            <!--              <img v-if="candidate.avatar !== 'no-avatar'" :src="candidate.avatar" alt="avatar" style="height: inherit">-->
            <!--            </v-avatar>-->
            <v-row>
              <v-col cols="12" class="py-0 py-0">
                <span class="d-none d-md-inline" :class="( selected ? 'primary--text': '' )">EMAIL:</span>
                <span :class="'font-weight-bold ml-2 ' + ( selected ? 'primary--text': '' ) + (candidate.rejected ? ' text-decoration-line-through' : '')">
                  {{ candidate.email }}
                </span>
              </v-col>
              <v-col cols="12" class="py-0 py-0 grey--text" style="font-size: smaller;  margin: -5px 0 0 9px;">
                <span class="d-none d-md-inline" :class="( selected ? 'primary--text': '' )">NAME:</span>
                <span :class="'ml-2 ' + ( selected ? 'primary--text': '' )">
                  {{ candidate.first_name }} {{ candidate.last_name }}
                </span>
              </v-col>
            </v-row>
          </v-col>

          <!--          <v-fade-transition>-->
          <!--            <v-icon v-if="favorite" class="fav-star" color="amber">mdi-star</v-icon>-->
          <!--          </v-fade-transition>-->

          <!-- <v-col cols="4" md="2" class="d-lg-flex d-none">

          </v-col> -->

          <v-col cols="4" class="text-right d-flex d-md-block align-center"
                 style="min-height: 95px; position: relative;">
            <div class="pr-4" style="position: absolute; right: 0; min-width: 250px;">
              <div class="text-overline grey--text text--lighten-1" style="line-height:1rem">
                BEWERTUNG
              </div>
              <div
                  class="text-h6"
                  :style="'color: ' + ratingToCol( candidate.rating )"
              >
                <div class="contacted-notes-viewed">
                  <v-icon small color="cyan">
                    {{ 'mdi-email-outline' }}
                  </v-icon>
                  <v-icon small :color="hasNotes ? 'cyan' : 'grey lighten-3'" class="flip-icon">mdi-file-outline
                  </v-icon>
                  <v-icon small :color="colorForStatus( 'viewed' )" class="mr-2">mdi-eye-outline</v-icon>
                </div>
                <span>{{ ratingToStr(candidate.rating) }}</span>
              </div>
              <div class="text-caption">
                {{ getTimeString(candidate.updated_at) }}
              </div>
            </div>
          </v-col>
          <span v-if="candidate.highlight" class="lightbulb primary">
            <v-icon dark>mdi-lightbulb-outline</v-icon>
          </span>
        </v-row>
      </v-card-text>
    </v-card>
  </v-hover>
</template>

<script>

import Utils from '@/service/Utils'

export default {
  props: {
    candidate: {type: Object},
    selected: {
      type: Boolean,
      default: false,
    }
    // to: { type: String },
  },
  computed: {
    hasNotes() {
      // const notes = this.candidate.details.notes || [];
      return true // this.candidate.hasNotes || (notes.length > 0);
    },
    favorite() {
      return false // this.candidate.statuses.includes('favorite');
    },
    candidateLink() {
      return `/job/${this.$route.params.jobId}/candidate/${this.candidate.id}`
    },
    // selected() {
    //   return false
    // },
  },
  methods: {
    onClickCard(event) {
      event
      this.$emit('clickCandidate', this.candidate)
    },
    getTimeString(str) {
      const date = new Date(str)
      return `${date.toLocaleDateString()} - ${date.toLocaleTimeString()}`
    },
    colorForStatus(status) {
      // const statuses = this.candidate.statuses;
      // if (statuses && statuses.includes(status)) {
      //   return {
      //     contacted: 'red',
      //     replied: 'cyan',
      //     viewed: 'black',
      //   }[status];
      // }
      status
      return 'grey lighten-3'
    },
    ratingToStr: Utils.ratingToStr,
    ratingToCol: Utils.ratingToCol
  }
}
</script>

<style scoped>

.rejected {
  opacity: .5;
}
.v-card * {
  cursor: pointer;
}

.flip-icon {
  transform: rotate(90deg) scaleX(-1);
}

.lightbulb {
  padding: 2px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.fav-star {
  position: absolute !important;
  top: 20px;
  left: 42px;
}

a:link {
  text-decoration: none !important;
}

.contacted-notes-viewed {
  position: absolute;
  right: 60px;
  min-width: 100px;
}
</style>
