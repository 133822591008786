<template>
  <div style="padding: 24px">
    <div class="mb-4">Sonstige Dateien</div>
    <file-upload
        type="recruiterFiles"
        emptyText="Keine Dateien hochgeladen"
        :availableFiles="candidate.recruiterFiles"
        :user="candidate"
        v-on:upload-end="$emit('reload');"
    ></file-upload>
    <div class="my-4">Lebensläufe</div>
    <file-upload
        :upload-allowed="false"
        type="cv"
        emptyText="Kein CV hochgeladen"
        :availableFiles="candidate.cv"
        :user="candidate"
    ></file-upload>
    <div class="my-4">Zeugnisse</div>
    <file-upload
        :upload-allowed="false"
        type="diplomas"
        emptyText="Keine Zeugnisse hochgeladen"
        :availableFiles="candidate.diplomas"
        :user="candidate"
    ></file-upload>
  </div>
</template>

<script>
import FileUpload from '@/components/recruiter-dashboard/CandidateDetails/FileUpload'

export default {
  name: 'FileTab',
  props: {
    candidate: Object
  },
  components: {
    FileUpload,
  },
  methods: {
  },
  watch: {
    candidate: Object
  }
}
</script>

<style scoped>

</style>