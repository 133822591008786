import Vue from 'vue'
import vuetify from './plugins/vuetify'
import router from './router'
import i18n from './i18n'

import App from './App'

// Vue GTag
import VueGtag from 'vue-gtag'
import VueMatomo from 'vue-matomo'

Vue.config.productionTip = false

Vue.use(VueGtag, {
  config: { id: 'GTM-MLDLQ82K' }
}, router)

Vue.use(VueMatomo, {
  host: 'https://matomo.brandmonks.de',
  siteId: 16,
  enableLinkTracking: true,
  trackInitialView: true,
  requireConsent: false,
})

new Vue({
  vuetify,
  router,
  i18n,
  render: h => h(App),
}).$mount('#app')
