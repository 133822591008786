<template>
  <div class="align-center d-flex" v-if="loggedIn">
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          text
          color="white"
          rounded
          class="px-0"
          dense
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-account</v-icon>
          <!-- <span class="ml-2">Konto</span> -->
          <v-icon>{{ dropStateIcon }}</v-icon>
        </v-btn>
      </template>
      <v-list class="pa-0">
        <v-list-item
          v-for="(item, index) in items"
          class="pa-0"
          :key="index"
        >
          <v-list-item-title style="min-height: 0" class="text-left">
            <v-btn text block class="text-left" left large @click="item.click">
              <span class="text-left">{{ item.title }}</span>
            </v-btn>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import RecruiterController from '@/controllers/recruiter-controller'

export default {
  data() {
    return {
      loading: false,
      items: [
        {
          title: 'Report runterladen',
          click: this.report,
        },
        {
          title: 'Ausloggen',
          click: this.logout,
        }
      ]
    }
  },
  computed: {
    dropStateIcon() {
      // return 'mdi-arrow-left-drop-circle'
      return 'mdi-menu-down'
    },
    loggedIn() {
      return this.$route.meta      && 
        this.$route.meta.auth      && 
        this.$route.meta.auth.role &&
        this.$route.meta.auth.role === 'recruiter'
    }
  },
  methods: {
    async logout() {
      await RecruiterController.logout()
      this.$router.push({ name: 'recruiterLogin' })
    },
    async report() {
      // @todo do this better
      const target = process.env.VUE_APP_BACKEND_URL + '/recruiter/report'

      window.open(target, '_blank')
    },
  }
}
</script>