import Axios from '@/plugins/axios'

const BASE = process.env.VUE_APP_BACKEND_URL || 'http://localhost:8000'

export default {
  async contact(data) {
    return Axios.post(`${BASE}/api/contact`, data)
  },

  async getCandidates() {
    return Axios.get(`${BASE}/candidates`)
  },

  async requestLogin(request) {
    return Axios.post(`${BASE}/candidate/request-login`, request)
  },

  async loginByToken(plainTextToken) {
    return Axios.post(`${BASE}/candidate/login-by-token/${plainTextToken}`)
  },

  async withdrawApplicationByToken(plainTextToken) {
    return Axios.post(`${BASE}/candidate/withdraw-application-by-token/${plainTextToken}`)
  },

  async get() {
    return Axios.get(`${BASE}/candidate/get`)
  },

  async logout() {
    return Axios.post(`${BASE}/candidate/logout`)
  },

  async patch(request) {
    return Axios.patch(`${BASE}/candidate/patch`, request)
  },

  async cv(request) {
    return Axios.post(`${BASE}/candidate/cv`, request)
  },

  async diplomas(request) {
    return Axios.post(`${BASE}/candidate/diplomas`, request)
  },

  async viewContent(request) {
    return Axios.post(`${BASE}/candidate/view-content`, request)
  },

  async recruiterFiles(request, user) {
    return Axios.post(`${BASE}/api/recruiterfiles/${user}`, request)
  },

  async surveyStarted() {
    return Axios.post(`${BASE}/candidate/survey-started`)
  },

  async surveyDone(request) {
    return Axios.post(`${BASE}/candidate/survey-done`, request)
  },

  async surveySuccessful(request) {
    return Axios.post(`${BASE}/candidate/survey-successful`, request)
  },

  async surveyFailed(request) {
    return Axios.post(`${BASE}/candidate/survey-failed`, request)
  },

  async availableRessorts() {
    return Axios.get(`${BASE}/candidate/available-ressorts`)
  },

  async nextQuestion(questionsAnsweredSoFar) {
    return Axios.post(`${BASE}/candidate/next-question/${questionsAnsweredSoFar}`)
  },

  async downloadRecruiterFiles(user, folder, file) {
    return Axios.get(
      `${BASE}/api/${user}/${folder}/${file}`,
      {
        responseType: 'blob'
      }
    )
  },

  async download(folder, file) {
    return Axios.get(
      `${BASE}/candidate/${folder}/${file}`,
      {
        responseType: 'blob'
      }
    )
  },

  async delete() {
    return Axios.delete(`${BASE}/candidate/delete`)
  },

  async create(email, progress, firstName, lastName) {
    return Axios.post(`${BASE}/api/user`, JSON.stringify({
      mail: email,
      answers: progress,
      firstName: firstName,
      lastName: lastName,
      referrer: document.referrer,
    }))
  },

  async patchCandiate(id, rating, docsUploaded, processStatus, appointment, resorts) {
    return Axios.patch(`${BASE}/api/candidate/${id}`, JSON.stringify({
      rating: rating,
      docs_uploaded: docsUploaded,
      process_status: processStatus,
      appointment: appointment,
      resorts: resorts // .map(resort => resort.id)
    }))
  }
}
