<template>
  <v-app>
    <router-view></router-view>
  </v-app>
</template>

<script>

import AuthController from './controllers/auth-controller'

export default {
  async beforeMount() {
    await AuthController.renewCsrfCookie()
  }
}
</script>
<style>
@supports(padding:max(0px)) {
  body, header, footer {
    padding-left: min(0vmin, env(safe-area-inset-left));
    padding-right: min(0vmin, env(safe-area-inset-right));
    padding-bottom: min(0vmin, env(safe-area-inset-bottom));
  }
}
</style>
