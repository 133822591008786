<template>
  <v-container fill-height>
    <v-row justify="center" align="center">
      <v-col class="d-flex justify-center">
        <router-link to="start?landing=5495">
          <video width="450" autoplay>
            <source :src="getVideoUrl('video5495.mp4')" type="video/mp4">
          </video>
        </router-link>
      </v-col>
      <v-col class="d-flex justify-center">
        <router-link to="start?landing=8821">
          <video width="450" autoplay>
            <source :src="getVideoUrl('video8821.mp4')" type="video/mp4">
          </video>
        </router-link>
      </v-col>
    </v-row>
    <v-row justify="center" align="center">
      <v-col class="d-flex justify-center">
        <router-link to="start?landing=1774">
          <video width="450" autoplay>
            <source :src="getVideoUrl('video1774.mp4')" type="video/mp4">
          </video>
        </router-link>
      </v-col>
      <v-col class="d-flex justify-center">
        <router-link to="start?landing=3432">
          <video width="450" autoplay>
            <source :src="getVideoUrl('video3432.mp4')" type="video/mp4">
          </video>
        </router-link>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'PresentationPage',
  methods: {
    getVideoUrl(vid) {
      return require(`@/assets/videos/${vid}`)
    },
  }
}
</script>

<style scoped>

</style>