<template>
  <v-expand-transition>
    <div v-show="enabled">
      <v-form ref="loginRequestForm" @submit.prevent="requestLogin">
        <h1 class="mb-4">Einloggen</h1>
        <v-card
            class="pa-4"
        >
          <v-card-text>
            <div>Ihre E-Mail</div>
            <v-text-field
                name="email"
                :rules="rules" 
                v-model="email"
                :append-icon="email ? 'mdi-close-circle' : ''"
                validate-on-blur
                :disabled="loading"
                :loading="loading"
                @click:append="clear"
            ></v-text-field>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                text
                type="submit"
                color="primary"
                :loading="loading"
                :disabled="!formIsValid"
            >
                Einmal-Login anfordern
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </div>
  </v-expand-transition>
</template>

<script>
import CandidateController from '@/controllers/candidate-controller'
import {emitNetworkError} from './utils'

export default {
  data() {
    return {
      formIsValid: false,
      loading: false,
      enabled: true,
      email: '',

      rules: [
        value => !!value || 'Ihre E-Mail wird benötigt.',
        value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Bitte tragen Sie eine gültige E-Mail ein.'
        },
      ],
            
      disabled: false,
      buttonText: null,
    }
  },
  async mounted(){
    // this.amILoggedIn()
  },
  methods: {
    async requestLogin(){
      this.loading = true

      try {
        const response = await CandidateController.requestLogin({email: this.email})
        const data = response.data

        if (data.ok) {
          this.$emit('alert', 'success', data.message)
          this.enabled = false
        } else {
          this.$emit('alert', 'info', data.message)
        }
      } catch (e) {
        console.log(e)
        emitNetworkError(this)
      }

      this.loading = false
    },
    clear(){
      this.email = ''
    }
  },
  watch: {
    email(){
      this.formIsValid = this.$refs.loginRequestForm.validate()
    }
  },
}
</script>
