<template>
  <div>
    <v-container>
      <v-text-field
          filled
          clearable
          rounded
          hide-details
          v-model="newComment"
          :append-outer-icon="newComment && newComment.length > 0 ? 'mdi-send' : 'mdi-'"
          prepend-inner-icon="mdi-file-document-edit-outline"
          clear-icon="mdi-close"
          label="Kommentar hinzufügen"
          type="text"
          class="mt-3 mb-2"
          @keyup.enter="addNewComment(candidate.id, newComment)"
          @click:append-outer="addNewComment(candidate.id, newComment)"
          @click:clear="clearNewComment(candidate.id, newComment)"
      ></v-text-field>
    </v-container>
    <div class="pr-8 pt-4"
         v-for="( comment, index ) of comments.filter(comment => comment.user_id === this.candidate.id)"
         :key="index">
      <v-slide-y-transition>
        <v-card
            outlined
            class="pt-5 pb-7 px-6 mb-4"
            style="position: relative"
        >
          <div class="d-flex justify-space-between align-center">
            <div class="d-flex justify-space-between">
              <v-icon color="grey" class="mr-5">mdi-file-document-outline</v-icon>
              <span>{{ comment.text }}</span>
              <slot></slot>
            </div>
            <v-hover v-slot="{ hover }">
              <v-btn icon class="my-auto btn-remove"
                     :color="hover ? 'red' : 'grey'"
                     @click="deleteComment(comment.id)"
              >
                <v-icon>mdi-minus-circle</v-icon>
              </v-btn>
            </v-hover>
          </div>
          <div style="position: absolute; bottom: -10px;">
            <span class="px-2 grey--text" style="background: white">{{ comment.created_at }}</span>
          </div>
        </v-card>
      </v-slide-y-transition>
    </div>
  </div>
</template>

<script>
import CommentController from '@/controllers/comment-controller'

export default {
  async beforeMount() {
    await this.getComment()
  },
  props: {
    candidate: Object
  },
  computed: {},
  data() {
    return {
      comments: [],
      newComment: ''
    }
  },
  methods: {
    async getComment() {
      try {
        const response = await CommentController.getComment()
        this.comments = response.data
        console.log('COMMENTS', this.comments)
      } catch (error) {
        console.log(error)
      }
    },
    async addNewComment(id, comment) {
      this.newComment = ''
      await CommentController.putComment(id, comment)
      await this.getComment()
    },
    clearNewComment() {
      this.newComment = ''
    },
    async deleteComment(id) {
      console.log('ESTSFSDAF')
      await CommentController.deleteComment(id)
      await this.getComment()
    }
  },
  components: {
  }
}
</script>

<style>
.btn-remove {
  margin-right: -30px !important;
  background: white;
}
</style>
