import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: { 
        'primary': '#8e0038', 
        'accent': '#bf1528', 
        'secondary': '#47bcc5', 
        'secondary-light': '#e2f6fa',
        'ergo-green': '#009284',
        'ergo-green-light': '#e3f0e6', 
        'ergo-yellow': '#fad73b',
        'ergo-yellow-light': '#fff4b0',
      }
    },
    options: {
      customProperties: true
    },
  },
  icons: {
    iconfont: 'mdi'
  },

})
